import { isNotEmpty, isPresent } from '@/utils/validators';
import { request } from '@/utils/request';
import { showUnableToGetDocument } from '@/utils/toast';

export const mimeTypeImages = ['image/jpeg', 'image/png'];
export const mimeTypeAttach = ['image/jpeg', 'image/png', 'application/pdf'];
export const mimeTypeInvoice = ['application/xml', 'text/xml', 'application/pkcs7-mime', 'application/x-pkcs7-mime'];
export const extensionImages = ['jpeg', 'png'];
export const extensionAttach = ['jpeg', 'png', 'pdf'];
export const extensionInvoice = ['.xml', '.p7m'];

export const buildDocName = (documentInfo) => `${documentInfo.documentEntityType}_${documentInfo.documentType}`;

export const decodeDocumentInfo = (item, translations) => {
  if (item.documentType === 'OTHER') {
    return item.documentTitle;
  }
  const docName = buildDocName(item);
  const label = translations[docName];

  return isPresent(label) ? label : `Documento ${docName}`;
};

export const decodeDocument = (item, translations) => decodeDocumentInfo(item.documentInfo, translations);

export const getTokenDocument = ({
  documentId,
  apiBaseUrl,
  callback,
  customUrl,
  publicDoc,
}) => {
  const options = {};
  if (isPresent(apiBaseUrl)) {
    options.apiBase = apiBaseUrl;
  }

  const url = customUrl || `/dms/view/${documentId}`;

  request(url, publicDoc, options)
    .then((resp) => {
      if (resp.status === 200) {
        callback({ token: resp.data, apiBaseUrl });
      } else {
        showUnableToGetDocument();
        console.log(resp);
      }
    })
    .catch((error) => {
      console.log(error);
      showUnableToGetDocument();
    });
};

const showDocument = ({ token, apiBaseUrl }) => {
  let baseUrl = apiBaseUrl || '';
  if (baseUrl === '' && process.env.NODE_ENV === 'production') {
    baseUrl = '/backoffice-api';
  }
  const tokenDoc = isNotEmpty(token.token) ? token.token : token;

  const url = `${baseUrl}/viewer/content/${tokenDoc}`;
  window.open(url);
};

export const openPrivateDocument = (documentId, apiBase, customUrl) => {
  getTokenDocument({
    documentId,
    apiBaseUrl: apiBase,
    callback: showDocument,
    customUrl,
  });
};

export const openPublicDocument = (documentId, apiBase, customUrl) => {
  getTokenDocument({
    documentId,
    apiBaseUrl: apiBase,
    callback: showDocument,
    customUrl,
    publicDoc: true,
  });
};

export const getEntityApiBase = (entityClassReq) => {
  const entityClass = entityClassReq.toUpperCase();

  if (entityClass === 'COMPANY' || entityClass === 'PERSON') {
    return '/backoffice-api';
  }

  if (entityClass === 'INVOICE') {
    return '/crp-invoice-api';
  }

  if (entityClass === 'TRANSACTION') {
    return '/wallet-api';
  }

  if (entityClass === 'USERREPORT' || entityClass === 'USERANALYSIS') {
    return '/crp-api';
  }

  return null;
};

export const createUriUpload = (doc, entityClass, entityId) => {
  const apiBase = getEntityApiBase(entityClass); // apiBase || '/credit-api';
  const entityType = entityClass.toLowerCase();
  return doc
    ? `${apiBase}/api/upload-doc/${entityType}/${entityId}/${doc.documentId}`
    : `${apiBase}/api/create-doc/${entityType}/${entityId}`;
};

export const getDocument = (documentsList, documentEntityType, documentType) => {
  console.log('getDocument params', documentsList, documentEntityType, documentType);
  const doc = documentsList
    .filter((item) => item.documentInfo.documentEntityType === documentEntityType && item.documentInfo.documentType === documentType);
  console.log('getDocument', documentsList, doc);
  return doc.length > 0 ? doc[0] : null;
};

export const createUriInvoiceUpload = (requestName) => {
  const apiBase = getEntityApiBase('INVOICE');
  return `${apiBase}/public/invoice-upload/${requestName}`;
};
