import Vue from 'vue';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import Vuelidate from 'vuelidate';
import axios from 'axios';
import VueAxios from 'vue-axios';
import AxiosPlugin from 'vue-axios-cors';
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome';
import vSelect from 'vue-select';
import VueToast from 'vue-toast-notification';
import VueNumeric from 'vue-numeric';
import VueClipboards from 'vue-clipboards';
import VueApollo from 'vue-apollo';

import plugin from 'vue-braintree';

// Da utilizzare per SEO
// https://medium.com/js-dojo/how-to-add-dynamic-meta-tags-to-your-vue-js-app-for-google-seo-b827e8c84ee9
import VueMeta from 'vue-meta';

import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import VueConfirmDialog from 'vue-confirm-dialog';
import { Datetime } from 'vue-datetime';
import { Settings } from 'luxon';
import VueFormWizard from 'vue-form-wizard';
import VueScrollTo from 'vue-scrollto';

import VueSocialSharing from 'vue-social-sharing';
import VueAddtohomescreen from '@owliehq/vue-addtohomescreen';

import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import App from './App.vue';
import router from './router';
import store from './store';

import '@fortawesome/fontawesome-free/css/fontawesome.min.css';
import 'vue-datetime/dist/vue-datetime.css';
import 'vue-toast-notification/dist/theme-sugar.css';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import setupInterceptors from './services/setupInterceptors';
import TokenService from '@/services/token.service';
import { setFrontendConfigs } from '@/utils/utilities';

library.add(fab, fas, far);

Vue.config.productionTip = false;
Vue.use(Vuelidate);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueAxios, axios);
Vue.use(AxiosPlugin);
Vue.use(VueConfirmDialog);
Vue.use(Datetime);
Vue.use(VueMeta);
Vue.use(VueToast);
Vue.use(VueFormWizard);
Vue.use(VueScrollTo);
Vue.use(VueNumeric);
Vue.use(VueClipboards);

Vue.use(VueSocialSharing);
Vue.use(VueAddtohomescreen);
Vue.use(VueApollo);
Vue.use(plugin);

// eslint-disable-next-line import/prefer-default-export
Vue.component('vue-confirm-dialog', VueConfirmDialog.default);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('font-awesome-layers', FontAwesomeLayers);
Vue.component('font-awesome-layers-text', FontAwesomeLayersText);
Vue.component('v-select', vSelect);
Vue.component('datetime', Datetime);

let urlGraphQL = `https://${window.location.host}/graphql-api`;

if (process.env.NODE_ENV !== 'production') {
  if (process.env.TARGET === 'local') {
    urlGraphQL = 'http://localhost:8080';
  } else {
    urlGraphQL = '/graphql-api';
  }
}
// HTTP connection to the API
const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: `${urlGraphQL}/graphql`,
});

// Cache implementation
const cache = new InMemoryCache();

// return the headers to the context so httpLink can read them
// const authLink = setContext((qlRequest, params) => {
const authLink = setContext((_, { headers, entityType, entityId }) => {
  // const { headers } = params;
  // console.log('authlink', qlRequest, params);
  // console.log('header', headers, TokenService.authHeader());
  const configs = {
    headers: {
      ...headers,
      ...TokenService.authHeader(),
      entityType,
      entityId,
    },
  };
  // console.log('configs', configs);
  return configs;
});

// Create the apollo client
const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
});

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

Settings.defaultLocale = 'it';

// Vue.mixin({
//   created() {
//     console.log('Setting', Settings);
//   },
// });

setupInterceptors(store, router);

if (process.env.NODE_ENV === 'production') {
  fetch(`${window.location.origin}/config/frontend.json`)
    .then((response) => response.json())
    .then((config) => {
      setFrontendConfigs(config);
      new Vue({
        router,
        store,
        // inject apolloProvider here like vue-router or vuex
        apolloProvider,
        render: (h) => h(App),
        // watch: {
        //   $route(to, from) {
        //     console.log('route', to, from);
        //   },
        // },
      }).$mount('#app');
    });
} else {
  setFrontendConfigs({
    badgeUrl: 'https://id.crpagamenti.it',
    portalUrl: 'https://plafond.crpagamenti.it',
  });
  new Vue({
    router,
    store,
    // inject apolloProvider here like vue-router or vuex
    apolloProvider,
    render: (h) => h(App),
  // watch: {
  //   $route(to, from) {
  //     console.log('route', to, from);
  //   },
  // },
  }).$mount('#app');
}
