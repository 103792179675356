import { request } from '@/utils/request';

const apiBase = '/wallet-api';

class WalletService {
    loadRechargeProduct = () => request('/recharge/product-list', false, { apiBase });

    loadPricingProduct = () => request('/pricing/product-list', false, { apiBase });

    loadEntityWallet = ({ entityType, entityId }) => {
      const url = `/user/wallet/${entityType}/${entityId}`;
      return request(url, false, { apiBase });
    };

    walletTransactionsList = ({
      entityType,
      entityId,
      search,
      page,
      size,
      sort,
      ...extraFilters
    }) => {
      const requestPage = page || 0;
      const requestSize = size || 10;
      const requestSort = sort || [];
      const requestSearch = search || '';

      return request(`/wallet/transactions/${entityType}/${entityId}`, false, {
        method: 'post',
        data: {
          search: requestSearch,
          page: requestPage,
          size: requestSize,
          sort: requestSort,
          ...extraFilters,
        },
        apiBase,
      });
    };

    exportWalletTransactionsList = ({
      entityType,
      entityId,
      search,
      page,
      size,
      sort,
      ...extraFilters
    }) => {
      const requestPage = page || 0;
      const requestSize = size || 10;
      const requestSort = sort || [];
      const requestSearch = search || '';

      return request(`/wallet/export-transactions/${entityType}/${entityId}`, false, {
        method: 'post',
        data: {
          search: requestSearch,
          page: requestPage,
          size: requestSize,
          sort: requestSort,
          ...extraFilters,
        },
        apiBase,
      });
    };

    createTransaction = ({
      entityType,
      entityId, description,
      productType,
      pricingLevel,
      productCode,
      paymentFor,
    }) => (
      request(`/wallet/product-transaction/${entityType}/${entityId}`, false, {
        method: 'post',
        data: {
          description,
          productType,
          productCode,
          pricingLevel,
          // operationDate,
          paymentFor,
        },
        apiBase,
      })
    );

    // getToken = () => request('/recharge/get-token', false, { apiBase });

    loadClientId = () => request('/recharge/paypal', false, { apiBase });

    rechargePayment = ({
      paymentMethodNonce,
      entityType,
      entityId,
      productId,
    }) => {
      let url = '/recharge/transaction';
      if (paymentMethodNonce === 'PAYPAL') {
        url = '/recharge/order/create';
      }
      return request(url, false, {
        method: 'post',
        data: {
          paymentMethodNonce,
          entityType,
          entityId,
          productId,
        },
        apiBase,
      });
    }

  completeOrderPayment = (orderId) => request(`/recharge/${orderId}/capture`, false, {
    method: 'post',
    apiBase,
  })

  deleteOrderPayment = (orderId) => request(`/recharge/${orderId}`, false, {
    method: 'delete',
    apiBase,
  })
}

export default new WalletService();
