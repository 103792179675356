import Vue from 'vue';
import lodash from 'lodash';
import { isNotEmpty } from '@/utils/validators';
import { toastUnableToComplete } from '@/utils/toast';
import WalletService from '@/services/wallet.service';

const initialState = {
  loading: 0,
  loadingExport: 0,
  errors: false,

  entityWallet: null,
  transactionList: {
    loading: false,
    content: null,
    pageable: null,
  },

  rechargeProduct: null,
  pricingProduct: null,

  clientId: null,
};

const wallet = {
  namespaced: true,
  state: initialState,
  getters: {
    getClientId: (state) => state.clientId,
    isLoading: (state) => state.loading !== 0,
    isLoadingExport: (state) => state.loadingExport !== 0,
    hasTransactionList: (state) => isNotEmpty(state.transactionList.pageable),
    userTransactionList: (state) => state.transactionList.content || [],
    userTransactionListPageable: (state) => state.transactionList.pageable || {},
    getWallet: (state) => state.entityWallet,
    hasWallet: (state) => isNotEmpty(state.entityWallet),
    getProductList: (state) => state.rechargeProduct || [],
    hasProductList: (state) => isNotEmpty(state.rechargeProduct),
    getPricingList: (state) => state.pricingProduct || [],
    hasPricingList: (state) => isNotEmpty(state.pricingProduct),
    getPricing: (state) => (productType, productCode, value) => {
      // console.debug('pricingList', productType, productCode, value);
      const price = (state.pricingProduct || [])
        .find((item) => item.productType === productType && item.productCode === productCode);
      // console.debug('pricingList found ', price);
      if (!price) {
        return { price: { currency: '', amount: -1 } };
      }
      if (value) {
        const { rangePrice } = price;
        if (rangePrice) {
          const priceLevel = rangePrice.find((item) => item.minLevel <= value && item.maxLevel >= value);
          if (priceLevel) {
            return lodash.cloneDeep(priceLevel);
          }
        }
      }
      return lodash.cloneDeep(price);
    },
    getPriceRange: (state, getters) => (productType, productCode) => {
      const price = getters.getPricing(productType, productCode);
      console.log('getPriceRange', price);
      const { rangePrice } = price;
      const range = { min: price.price, max: price.price };
      if (rangePrice) {
        const priceLevels = rangePrice.map((item) => item.price.amount);
        const min = Math.min(...priceLevels);
        const max = Math.max(...priceLevels);
        console.debug('get price range minmax', priceLevels, min, max);
        range.min = rangePrice.find((item) => item.price.amount === min);
        range.max = rangePrice.find((item) => item.price.amount === max);
      }
      return range;
    },
  },

  actions: {
    loadClientId({ commit }) {
      // console.log('state', this.state);
      return WalletService.loadClientId().then(
        (response) => {
          commit('clientIdSuccess', response.data);
          return Promise.resolve(response.data);
        },
        (error) => Promise.reject(error),
      );
    },
    loadRechargeProduct({ commit }) {
      // console.log('state', this.state);
      commit('setLoading', true);
      return WalletService.loadRechargeProduct().then(
        (response) => {
          commit('productSuccess', response.data);
          commit('setLoading', false);

          return Promise.resolve(response.data);
        },
        (error) => {
          commit('setLoading', false);

          return Promise.reject(error);
        },
      );
    },
    loadPricingProduct({ commit }) {
      // console.log('state', this.state);
      commit('setLoading', true);
      return WalletService.loadPricingProduct().then(
        (response) => {
          commit('pricingSuccess', response.data);
          commit('setLoading', false);

          return Promise.resolve(response.data);
        },
        (error) => {
          commit('setLoading', false);

          return Promise.reject(error);
        },
      );
    },
    loadWallet({ commit }, {
      entityType,
      entityId,
    }) {
      commit('setLoading', true);
      return WalletService.loadEntityWallet({
        entityType,
        entityId,
      }).then(
        (response) => {
          commit('setEntityWallet', response.data);
          commit('setLoading', false);
          return Promise.resolve(response.data);
        },
        (error) => {
          commit('setLoading', false);
          toastUnableToComplete();
          return Promise.reject(error);
        },
      );
    },
    transactionsList({ commit }, {
      entityType,
      entityId,
      search, reset,
    }) {
      commit('clearTransactionSearch');
      commit('setLoading', true);
      if (reset) {
        commit('clearTransactionSearch');
      }
      return WalletService.walletTransactionsList({
        entityType,
        entityId,
        ...search,
      }).then(
        (response) => {
          commit('transactionSearchSuccess', { data: response.data });
          commit('setLoading', false);
        },
        (error) => {
          commit('clearTransactionSearch');
          commit('setLoading', false);
          toastUnableToComplete();
          return Promise.reject(error);
        },
      );
    },
    exportTransactionsList({ commit }, {
      entityType,
      entityId,
      search,
    }) {
      commit('setLoadingExport', true);
      return WalletService.exportWalletTransactionsList({
        entityType,
        entityId,
        ...search,
      }).then(
        (response) => {
          this.dispatch('addExportToken', response.data.data);
          commit('setLoadingExport', false);
        },
        (error) => {
          commit('setLoadingExport', false);
          toastUnableToComplete();
          return Promise.reject(error);
        },
      );
    },
    createTransaction({ commit }, {
      entityType,
      entityId, description,
      pricingLevel,
      productType,
      productCode,
      paymentFor,
    }) {
      commit('setLoading', true);
      return WalletService.createTransaction({
        entityType: entityType.toLowerCase(),
        entityId,
        description,
        pricingLevel,
        productType,
        productCode,
        paymentFor,
      }).then(
        (response) => {
          commit('setLoading', false);
          if (response.status < 300) {
            return Promise.resolve(response.data);
          }
          toastUnableToComplete();
          return Promise.reject(response.data);
        },
        (error) => {
          commit('setLoading', false);
          toastUnableToComplete();
          return Promise.reject(error);
        },
      );
    },
  },
  mutations: {
    setLoading(state, loading) {
      state.loading += (loading ? 1 : -1);
    },
    setLoadingExport(state, loading) {
      state.loadingExport += (loading ? 1 : -1);
    },
    productSuccess(state, productList) {
      state.rechargeProduct = productList;
    },
    pricingSuccess(state, productList) {
      state.pricingProduct = productList;
    },
    setEntityWallet(state, walletDetail) {
      state.entityWallet = walletDetail;
    },
    clearTransactionSearch(state) {
      const current = state.transactionList || { };
      Vue.set(state, 'transactionList', {
        ...current,
        content: null,
        pageable: null,
      });
    },
    transactionSearchSuccess(state, { data }) {
      const { content, ...pageable } = data;
      const current = state.transactionList || { };
      Vue.set(state, 'transactionList', { ...current, content, pageable });
    },
    clientIdSuccess(state, clientId) {
      state.clientId = clientId;
    },
  },
};

export default wallet;
