const errorMessages = {
  'confirmEmail.sameAsEmail': 'Le email devono essere uguali',
  'personTaxCode.checkTaxCode': 'Codice fiscale non valido',
  'personTaxCode.existTaxCode': 'Codice fiscale già presente',
  'anagraficalInfo.taxCode.checkTaxCode': 'Codice fiscale non valido',
  'confirmPassword.sameAsPassword': 'Le password devono essere uguali',
  'iban.checkIban': 'Inserire un IBAN corretto',
  'iban.ibanNotDuplicate': 'IBAN già presente',
  'swift.checkBic': 'Codice BIC/SWIFT non valido',
  'anagraficalInfo.birthDate.checkAge': 'Deve essere maggiorenne',
  'docIdentity.expireDate.checkExpired': 'Il documento deve avere almeno sei mesi di validità',
  'personInfo.email.email': 'Email invalida',
  'anagraficalInfo.mainPecEmail.contactData.email': 'Indirizzo PEC non valido',
  'docNameOther.checkOtherDocument': 'Il nome del documento è obbligatorio',
  'userEmail.email': 'Email non valida',
  'expireDate.validDate': 'La data di scadenza deve avere almeno 15gg da oggi',
};

export default errorMessages;
