import { required } from 'vuelidate/lib/validators';
import { isEmail as rawIsEmail } from 'validator';
import CodiceFiscale from 'codice-fiscale-js';
import moment from 'moment';
import {
  checkVAT,
  france,
  germany,
  italy,
  portugal,
  spain,
  switzerland,
  unitedKingdom,
} from 'jsvat';
import errorMessages from '@/utils/errorMessages';

export const VALIDATE_ADDRESS = {
  countryCode: {
    required,
  },
  streetName: {
    required,
  },
  zip: {
    required,
  },
  city: {
    required,
  },
  province: {
    required,
  },
};

export const VALIDATE_CITY = {
  countryCode: {
    required,
  },
  city: {
    required,
  },
  province: {
    required,
  },
};

export const isPresent = (value) => value !== undefined && value !== null
  && String(value).trim().length > 0;

export const isNotEmpty = (value) => value !== undefined && value !== null;
// export const isNotEmpty = (value) => value !== undefined && value !== null && value.length > 0;

export const isNotExist = (value) => value === undefined || value === null;

export const isArrayNotEmpty = (value) => {
  if (value === undefined || value === null) return false;

  return value.length > 0;
};

export const isEmail = (value) => rawIsEmail(String(value));

// eslint-disable-next-line no-unused-vars
export const validateFormFieldState = (item, checkList) => {
  if (isNotExist(item)) return null;
  const { $dirty, $error } = item;
  // const check = $dirty ? !$error : null;
  // console.log('item', item, check);
  return $dirty ? !$error : null;
  // if ($dirty) {
  //   if (isNotEmpty(checkList) && checkList.length > 0) {
  //     console.log('checks', checkList.map((check) => item[check]));
  //     const checks = checkList.map((check) => item[check]).filter((check) => !check);
  //     return checks.length > 0;
  //   }
  //   return !$error;
  // }
  // return null;
};

export const validateFormFieldInvalid = (item) => {
  // console.log('item', item);
  if (isNotExist(item)) return null;
  const { $invalid } = item;
  return !$invalid;
};

export const decodeErrorMessage = (item, validator) => errorMessages[`${item}.${validator}`];

// eslint-disable-next-line no-unused-vars
export const decodeFormFieldError = (form, item, formErrors) => {
  let field;
  if (item.indexOf('.') > 0) {
    const names = item.split('.');
    field = form;
    for (let i = 0; i < names.length; i += 1) {
      field = field[names[i]];
    }
  } else {
    field = form[item];
  }

  // console.log('error', item, , Object.keys(form[item]));
  let error = '';
  // eslint-disable-next-line dot-notation
  if (field['$error']) {
    const checks = Object.keys(field)
      .filter((key) => !key.startsWith('$'))
      .filter((check) => !field[check]);
    if (checks.length > 0) {
      const validator = checks[0];
      if (validator === 'serverError') {
        // console.log('serverErrors', formErrors);
        error = formErrors.serverErrors[item];
      } else {
        error = errorMessages[`${item}.${validator}`];
      }
      // console.log('keys', Object.keys(field), checks, error);
    }
  }
  return error;
};

export const omitNull = (objToClean) => {
  const obj = isNotEmpty(objToClean) ? { ...objToClean } : {};
  Object.keys(obj).filter((k) => obj[k] === null).forEach((k) => delete obj[k]);
  return obj;
};

export const fieldStateAddress = (addressModel) => ({
  countryCode: validateFormFieldState(addressModel.countryCode),
  city: validateFormFieldState(addressModel.city),
  province: validateFormFieldState(addressModel.province),
  zip: validateFormFieldState(addressModel.zip),
  streetName: validateFormFieldState(addressModel.streetName),
});

export const fieldStateCity = (addressModel) => ({
  countryCode: validateFormFieldState(addressModel.countryCode),
  city: validateFormFieldState(addressModel.city),
  province: validateFormFieldState(addressModel.province),
});

export const removeProp = (obj, propName) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const p in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(p)) {
      if (p === propName) {
        // eslint-disable-next-line no-param-reassign
        delete obj[p];
      } else if (typeof obj[p] === 'object') {
        removeProp(obj[p], propName);
      }
    }
  }
  return obj;
};

export const extractErrorMessage = (error) => {
  if (isNotEmpty(error)) {
    const errMsg = (error.response && error.response.data && error.response.data.message);
    return errMsg || error.message || error.toString();
  }
  return null;
};

export const serverError = (fieldName) => (value, vm) => {
  console.log('serverError', fieldName, value, vm);
  return !(
    // eslint-disable-next-line no-prototype-builtins
    vm.hasOwnProperty('serverErrors') && vm.serverErrors.hasOwnProperty(fieldName)
  );
};

export const convertSetFieldName = (obj, field, value) => {
  // // protect against being something unexpected
  // obj = typeof obj === 'object' ? obj : {};
  // split the field into and array if its not one already
  const keys = Array.isArray(field) ? field : field.split('.');
  // keep up with our current place in the object
  // starting at the root object and drilling down
  let curStep = obj;
  // loop over the field parts one at a time
  // but, dont iterate the last part,
  for (let i = 0; i < keys.length - 1; i += 1) {
    // get the current field part
    const key = keys[i];

    // if nothing exists for this key, make it an empty object or array
    if (!curStep[key] && !Object.prototype.hasOwnProperty.call(curStep, key)) {
      // get the next key in the field, if its numeric, make this property an empty array
      // otherwise, make it an empty object
      const nextKey = keys[i + 1];
      const useArray = /^\+?(0|[1-9]\d*)$/.test(nextKey);
      curStep[key] = useArray ? [] : {};
    }
    // update curStep to point to the new level
    curStep = curStep[key];
  }
  // set the final key to our value
  const finalStep = keys[keys.length - 1];
  curStep[finalStep] = value;
};

export const checkTaxCode = (value) => {
  try {
    // console.log('checkTaxCode', value);
    const cf = new CodiceFiscale(value);
    if (cf.isValid()) return true;
    // console.log('checkTaxCode ->', cf.toJSON());
    return false;
  } catch (e) {
    return false;
  }
};

export const checkAge = (value, form) => {
  const age = moment().diff(form.birthDate, 'years');
  // console.log('form', form, age);
  return age > 17;
};

export const checkExpired = (value, form) => {
  // console.log('checkExpired', form);
  if (!isPresent(form.expireDate)) {
    return false;
  }
  const age = moment().diff(form.expireDate, 'days');
  // console.log('checkExpired calc', form.expireDate, age);
  return age ? age < -180 : false;
};

export const validateVat = (vatNumber) => {
  if (!isPresent(vatNumber)) return false;

  let temp;
  temp = vatNumber.toUpperCase();
  let check = checkVAT(temp, [
    italy,
    unitedKingdom,
    france,
    germany,
    switzerland,
    spain,
    portugal,
  ]).isValid;

  if (!check) {
    temp = `IT${vatNumber}`;
    check = checkVAT(temp, [
      italy,
      unitedKingdom,
      france,
      germany,
      switzerland,
      spain,
      portugal,
    ]).isValid;
  }
  return check;
};

export const sha256 = async (data) => {
  const textAsBuffer = new TextEncoder().encode(data);
  const hashBuffer = await window.crypto.subtle.digest('SHA-256', textAsBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const digest = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
  return digest;
};

const code = '348c32ae4c6468f5b15082679ddb01000e7869553a52001d293250621da4ea11';
// EARLYACCESS2023
export const checkInviteCode = async (value) => {
  const hash = await sha256(value);
  console.log('checkInviteCode', hash);

  return (hash === code);
};
