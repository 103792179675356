<template>
  <div>
    <user-structure
      hide-button
      hide-switch
      :title="headingText"
    >
      <template #body>
        <b-card class="border-0">
          <b-row>
            <b-col md="4" class="d-flex mt-3 mt-md-0 flex-column">
              <b-card footer-class="p-0 overflow-hidden">
                <template #header>
                  <card-header
                    title="Credito disponibile"
                  />
                </template>
                <div class="text-center py-4">
                  <h1 :class="totalPointHighlightClasses">
                    {{computedCredit}}
                    <span>
                      {{walletCurrency}}
                    </span>
                  </h1>
                </div>
              </b-card>
              <div class="mt-4" v-if="walletProducts.length > 0">
                <div
                  v-for="walletProduct in walletProducts"
                  :key="walletProduct.productId"
                  class="mb-3"
                >
                  <wallet-button
                    :item="walletProduct"
                    :buy-product="buyProduct"
                    :price-denominator="priceDenominator"
                  />
                </div>
              </div>
              <div
                v-if="isOperative"
                class="mt-3"
              >
                <b-button
                  size="lg"
                  variant="info"
                  block
                  @click="routeTo('wallet-topup')"
                >
                  Ricarica
                </b-button>
              </div>
            </b-col>
            <b-col md="8" class="mt-3 mt-md-0">
              <b-card>
                <template #header>
                  <card-header
                    title="Transazioni"
                  />
                </template>

                <wallet-transaction-table
                  :is-loading="isLoadingTransactions"
                  :entity-type="entityType"
                  :entity-id="entityId"
                />
              </b-card>
            </b-col>
          </b-row>
        </b-card>
      </template>
    </user-structure>
    <modal-payment id="paymentModal" :product="currentProduct"/>
  </div>
</template>

<script>
import { isNotEmpty } from '../utils/validators';
import { formatDecimalNumber } from '../utils/formatterHelper';

const UserStructure = () => import('@/components/layout/UserStructure.vue');
const WalletTransactionTable = () => import('@/components/userWallet/WalletTransactionTable.vue');
const WalletButton = () => import('@/components/userSections/WalletButton.vue');
const ModalPayment = () => import('@/components/recharge/ModalPayment.vue');
const CardHeader = () => import('@/components/cardHeader/CardHeader.vue');

export default {
  name: 'UserWallet',
  components: {
    UserStructure,
    WalletTransactionTable,
    WalletButton,
    ModalPayment,
    CardHeader,
  },
  data() {
    return {
      hasLoaded: false,
      currentProduct: null,
    };
  },
  props: {
    totalPoint: Number,
  },
  computed: {
    headingText() {
      return 'Wallet';
    },
    shoutBoxData() {
      return {
        title: this.headingText,
      };
    },
    computedCredit() {
      if (isNotEmpty(this.$store.getters['wallet/getWallet']?.amount)) {
        const a = this.$store.getters['wallet/getWallet'].amount.amount;
        return formatDecimalNumber(a, 2, 'it');
      }
      return 0;
    },
    totalPointHighlightClasses() {
      const baseClasses = 'm-auto point-highlight point-highlight--wallet';
      if (isNotEmpty(this.$store.getters['wallet/getWallet']?.amount)) {
        const a = this.$store.getters['wallet/getWallet'].amount.amount.toString().length;
        if (a >= 3 && a < 5) {
          return `${baseClasses} point-highlight--100`;
        }
        if (a >= 5 && a < 8) {
          return `${baseClasses} point-highlight--1000`;
        }
        if (a >= 8) {
          return `${baseClasses} point-highlight--10000`;
        }
      }
      return baseClasses;
    },
    walletCurrency() {
      return this.$store.getters['wallet/getWallet']?.amount.currency || '';
    },
    isCompanyProfile() {
      const currentProfile = this.$store.getters['subject/currentCompany'];
      return isNotEmpty(currentProfile);
    },
    activityStatus() {
      if (this.isCompanyProfile) {
        return this.$store.getters['subject/activityCompanyStatus'];
      }
      return this.$store.getters['subject/activityPersonStatus'];
    },
    isOperative() {
      return this.activityStatus.operative;
    },
    entityType() {
      return this.isCompanyProfile ? 'company' : 'person';
    },
    entityId() {
      const currentProfile = this.$store.getters['subject/currentCompany'];
      if (isNotEmpty(currentProfile)) {
        return currentProfile.companyId;
      }
      return this.$store.getters['subject/currentPerson']?.personId;
    },
    isLoadingTransactions() {
      return this.$store.getters['wallet/isLoading'];
    },
    walletProducts() {
      return this.$store.getters['wallet/getProductList'].filter((item) => item.highlight);
    },
    priceDenominator() {
      if (isNotEmpty(this.walletProducts)) {
        const ratio = this.walletProducts.map((item) => item.creditsPurchased.amount / item.price.amount);
        return Math.min(...ratio);
      }
      return 1;
    },
  },
  metaInfo() {
    return {
      title: 'Area utente - CR Pagamenti',
      meta: [
        { property: 'og:title', content: 'Area utente - CR Pagamenti' },
        { property: 'og:site_name', content: 'CR Pagamenti' },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'noindex,nofollow' },
      ],
    };
  },
  methods: {
    changePreloaderStatus(state) {
      this.hasLoaded = state;
      console.debug('UserWallet setpreloader', !state);
      this.$store.dispatch('setPreloader', !state);
    },
    routeTo(path) {
      this.$router.replace(path);
    },
    buyProduct(product) {
      this.currentProduct = product;
      this.$bvModal.show('paymentModal');
    },
  },
};
</script>

<style scoped>

</style>
