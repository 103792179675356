<template>
  <user-structure hide-button no-toolbar>
    <template #shoutbox>
      <div class="mb-5">
        <shout-box
          :data="shoutBoxData"
        />
      </div>
    </template>
    <template #body>
      <div class="bg-white p-3">
        <user-analysis-wizard :request-type="requestType"/>
      </div>
    </template>
  </user-structure>
</template>

<script>
const UserStructure = () => import('@/components/layout/UserStructure.vue');
const ShoutBox = () => import('@/components/ShoutBox.vue');
const UserAnalysisWizard = () => import('@/components/wizards/UserAnalysisWizard.vue');

export default {
  name: 'UserAnalysisRequest',
  components: {
    UserAnalysisWizard,
    UserStructure,
    ShoutBox,
  },
  props: {
    requestType: String,
  },
  data() {
    return {
      hasLoaded: null,
    };
  },
  computed: {
    shoutBoxData() {
      return {
        title: 'Richiesta servizio',
      };
    },
  },
  metaInfo() {
    return {
      title: 'Area utente - CR Pagamenti',
      meta: [
        { property: 'og:title', content: 'Area utente - CR Pagamenti' },
        { property: 'og:site_name', content: 'CR Pagamenti' },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'index,follow' },
      ],
    };
  },
  methods: {
  },
};
</script>
