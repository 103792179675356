import Vue from 'vue';
import DocumentService from '@/services/document.service';
import { isNotEmpty, isNotExist } from '@/utils/validators';
import { toastUnableToComplete } from '@/utils/toast';
import { buildDocName, decodeDocument } from '@/utils/documents';

const initialState = {
  loading: false,
  documentTypes: null,
  updateDoc: {},
  documentWfw: null,
  documentsList: {
    person: {},
    company: {},
  },
  error: {
    updateDoc: null,
    documentTypes: null,
    documentWfw: null,
  },
};

const documents = {
  namespaced: true,
  state: initialState,
  getters: {
    documentTypes: (state) => state.documentTypes,
    documentWfw: (state) => state.documentWfw,
    isLoading: (state) => state.loading,
    hasDocumentTypes: (state) => isNotEmpty(state.documentTypes),
    isWfwLoaded: (state) => isNotEmpty(state.documentWfw),
    getDocumentTypeList: (state) => (type) => {
      const documentTypes = state.documentTypes?.documents || {};
      return documentTypes[type] || ['OTHER'];
    },
    getDocumentTranslation: (state) => state.documentTypes?.translations || {},
    decodeDocumentName: (state) => (doc) => {
      if (isNotEmpty(state.documentTypes)) {
        return decodeDocument(doc, state.documentTypes.translations);
      }
      return doc.documentInfo.documentType;
    },
    documentInfo: (state) => (doc) => {
      if (isNotEmpty(state.documentTypes) && isNotEmpty(doc)) {
        const docName = buildDocName(doc.documentInfo);
        return state.documentTypes.info[docName];
      }
      return '';
    },
    documentAdditionalInfos: (state) => (doc) => {
      if (isNotEmpty(state.documentTypes)) {
        const docName = buildDocName(doc.documentInfo);
        return state.documentTypes.additionalInfos[docName];
      }
      return '';
    },
    documentPoints: (state) => (doc) => {
      if (isNotEmpty(state.documentTypes)) {
        const docName = buildDocName(doc.documentInfo);
        return state.documentTypes.points[docName];
      }
      return '';
    },
    documentSignature: (state) => (doc) => {
      if (isNotEmpty(state.documentTypes)) {
        const docName = buildDocName(doc.documentInfo);
        return state.documentTypes.signature[docName];
      }
      return '';
    },
    documentExternalUrl: (state) => (doc) => {
      if (isNotEmpty(state.documentTypes)) {
        const docName = buildDocName(doc.documentInfo);
        return state.documentTypes.externalUrls[docName];
      }
      return '';
    },
    documentExtensions: (state) => (doc) => {
      if (isNotEmpty(state.documentTypes)) {
        const docName = buildDocName(doc.documentInfo);
        return state.documentTypes.extensions[docName];
      }
      return [];
    },
    hasDocumentList: (state) => (entityClass, entityId) => {
      const entityType = entityClass.toLowerCase();
      const entityList = state.documentsList[entityType] || {};
      return isNotEmpty(entityList[entityId]);
    },
    getDocumentList: (state) => (entityClass, entityId) => {
      const entityType = entityClass.toLowerCase();
      const entityList = state.documentsList[entityType] || {};
      return entityList[entityId] || [];
    },
    getDocument: (state) => (entityClass, entityId, documentEntityType, documentType) => {
      // console.log('getDocument params', entityClass, entityId, documentEntityType, documentType);
      const entityType = entityClass.toLowerCase();
      const { documentsList } = state;
      const entityList = documentsList[entityType] || {};
      const docs = entityList[entityId] || [];
      const doc = docs
        .filter((item) => item.documentInfo.documentEntityType === documentEntityType && item.documentInfo.documentType === documentType);
      // console.log('getDocument', entityList, entityList[entityId], docs, doc);
      return doc.length > 0 ? doc[0] : null;
    },
  },
  actions: {
    loadDocTypes({ commit }, language) {
      commit('setLoading', true);
      return DocumentService.documentTypes(language).then(
        (response) => {
          commit('typesSuccess', response.data);
          commit('setLoading', false);
          return Promise.resolve(response);
        },
        (responseError) => {
          commit('setErrors', { section: 'documentTypes', errors: true });
          commit('setLoading', false);
          toastUnableToComplete();
          return Promise.reject(responseError);
        },
      );
    },
    loadDocWfw({ commit }) {
      commit('setLoading', true);
      return DocumentService.documentWfw().then(
        (response) => {
          commit('wfwSuccess', response.data);
          commit('setLoading', false);
          return Promise.resolve(response);
        },
        (responseError) => {
          commit('setErrors', { section: 'documentWfw', errors: true });
          commit('setLoading', false);
          toastUnableToComplete();
          return Promise.reject(responseError);
        },
      );
    },
    updateDocument({ commit }, {
      document,
      entityClass,
      entityId,
    }) {
      commit('setLoading', true);
      return DocumentService.updateDocument({
        doc: document,
        entityClass,
        entityId,
        sectionCode: 'info',
      }).then(
        (response) => {
          commit('setLoading', false);
          return Promise.resolve(response.data);
        },
        (responseError) => {
          commit('setErrors', {
            section: 'updataDoc',
            errors: responseError.data.errors,
          });
          commit('setLoading', false);
          toastUnableToComplete();
          return Promise.reject(responseError.data);
        },
      );
    },
    loadDocuments({ commit }, {
      entityClass,
      entityId,
    }) {
      commit('setLoading', true);
      return DocumentService.loadEntityDocuments({
        entityClass,
        entityId,
      }).then(
        (response) => {
          const entityType = entityClass.toLowerCase();
          if (entityType !== 'company') {
            commit('setDocumentLoaded', { entityClass, entityId, list: response.data });
          } else {
            const {
              companyDocuments, financialDocuments, registrationDocuments, ownerDocuments, bankDocuments, legalDocuments,
            } = response.data;
            const companyList = [...companyDocuments, ...financialDocuments, ...registrationDocuments, ...ownerDocuments, ...bankDocuments];
            commit('setDocumentLoaded', { entityClass, entityId, list: companyList });
            if (legalDocuments.length > 0) {
              const personId = legalDocuments[0].referent.entityId;
              commit('setDocumentLoaded', { entityClass: 'person', entityId: personId, list: legalDocuments });
            }
          }
          commit('setLoading', false);
          return Promise.resolve(response.data);
        },
        (responseError) => {
          commit('setLoading', false);
          toastUnableToComplete();
          return Promise.reject(responseError);
        },
      );
    },
    setDocument({ commit }, { entityClass, entityId, doc }) {
      const entityList = this.getters['documents/getDocumentList'](entityClass, entityId);
      const list = entityList.map((item) => {
        if (item.documentId === doc.documentId) {
          return doc;
        }
        return item;
      });
      commit('setDocumentLoaded', { entityClass, entityId, list });
    },
    addDocument({ commit }, { entityClass, entityId, doc }) {
      const entityList = this.getters['documents/getDocumentList'](entityClass, entityId);
      const list = [...entityList, doc];
      commit('setDocumentLoaded', { entityClass, entityId, list });
    },
    setDocuments({ commit }, { entityClass, entityId, docs }) {
      commit('setDocumentLoaded', { entityClass, entityId, list: docs });
    },
  },
  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },
    typesSuccess(state, types) {
      state.documentTypes = types;
      state.error.documentTypes = null;
    },
    wfwSuccess(state, types) {
      state.documentWfw = types;
      state.error.documentWfw = null;
    },
    setErrors(state, { section, errors }) {
      // console.log('setErrors');
      state.error[section] = errors;
    },
    setDocumentLoaded(state, { entityClass, entityId, list }) {
      const entityType = entityClass.toLowerCase();
      if (isNotExist(state.documentsList[entityType])) {
        Vue.set(state.documentsList, entityType, { });
      }
      const entityList = state.documentsList[entityType];
      entityList[entityId] = list;
      state.documentsList[entityType] = { ...entityList };
      // Vue.set(state.documentsList, entityType, { ...entityList });
    },
  },
};

export default documents;
