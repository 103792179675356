import Vue from 'vue';
// https://github.com/ankurk91/vue-toast-notification
const baseOptions = {
  position: 'top-right',
};

const composeOption = (options) => {
  if (options) {
    return { ...baseOptions, ...options };
  }
  return baseOptions;
};

export const toastSuccess = (message, option) => Vue.$toast.success(message, composeOption(option));
export const toastSuccessWait = (message, option) => Vue.$toast.success(message, { ...composeOption(option), duration: 0 });
export const toastFailure = (message, option) => Vue.$toast.error(message, composeOption(option));
export const toastInformation = (message, option) => Vue.$toast.info(message, composeOption(option));
export const toastWarning = (message, option) => Vue.$toast.warning(message, composeOption(option));

export const toastUnableToComplete = () => toastFailure('Operazione non completata');

export const showUnableToGetDocument = () => toastFailure('Non è stato possibile recuperare il documento');
