<template>
  <div class="lds-roller__wrapper">
    <div class="lds-roller">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
// import { ContentLoader } from 'vue-content-loader';
// https://github.com/egoist/vue-content-loader
// https://skeletonreact.com/
export default {
  name: 'LoaderInfo',
  components: {},
};
</script>

<style scoped>

</style>
