import Vue from 'vue';
import ScoreService from '@/services/score.service';
import { toastFailure, toastSuccess, toastUnableToComplete } from '@/utils/toast';
import { isArrayNotEmpty, isNotEmpty, isNotExist } from '@/utils/validators';

const emptyList = {
  loading: false,
  content: null,
  pageable: null,
};

const defaultSearchName = 'defaultSearch';

const initialState = {
  loading: 0,
  userScore: null,
  companyScore: null,
  feedbackUser: {
    defaultSearch: { ...emptyList },
  },
  creatingFeedback: false,
  userReports: {
    defaultSearch: { ...emptyList },
  },
  userReport: null,
  loadingClub: false,
};

const getSearchName = (searchName) => searchName || defaultSearchName;

const score = {
  namespaced: true,
  state: initialState,
  getters: {
    isLoading: (state) => state.loading !== 0,
    isLoadingClub: (state) => state.loadingClub,
    isCreatingFeedback: (state) => state.creatingFeedback,
    isFeedbackUser: (state) => (searchName) => state.feedbackUser[getSearchName(searchName)]?.loading,
    hasFeedbackUsers: (state) => (searchName) => isNotEmpty(state.feedbackUser[getSearchName(searchName)]?.pageable),
    feedbackUserList: (state) => (searchName) => state.feedbackUser[getSearchName(searchName)]?.content || [],
    feedbackUserPageable: (state) => (searchName) => state.feedbackUser[getSearchName(searchName)]?.pageable || {},
    getCurrentScore: (state) => (isNotExist(state.companyScore) ? state.userScore : state.companyScore),
    hasCurrentScore: (state) => isNotEmpty(state.companyScore) || isNotEmpty(state.userScore),
    // eslint-disable-next-line max-len
    getCurrentReportReceived: (state) => (isNotExist(state.companyScore) ? state.userScore?.reportReceived : state.companyScore.reportReceived),
    getCurrentReportSend: (state) => (isNotExist(state.companyScore) ? state.userScore?.reportSend : state.companyScore.reportSend),
    isUserReportsLoading: (state) => (searchName) => state.userReports[getSearchName(searchName)]?.loading,
    hasUserReports: (state) => (searchName) => isNotEmpty(state.userReports[getSearchName(searchName)]?.pageable),
    userReportsList: (state) => (searchName) => state.userReports[getSearchName(searchName)]?.content || [],
    userReportsPageable: (state) => (searchName) => state.userReports[getSearchName(searchName)]?.pageable || {},
    getCurrentUserReport: (state) => state.userReport,
    hasCurrentUserReport: (state) => isNotEmpty(state.userReport),
  },
  actions: {
    getEntityScore({ commit }, {
      entityType,
      entityId,
    }) {
      commit('setLoading', true);
      return ScoreService.loadScoreDetail({
        entityType,
        entityId,
      }).then(
        (response) => {
          if (entityType === 'person') {
            commit('setCompanyScore', null);
            commit('setUserScore', response.data);
          } else {
            commit('setCompanyScore', response.data);
          }
          commit('setLoading', false);
          // this.dispatch('setPreloader', false);
          return Promise.resolve(response.data);
        },
        (error) => {
          commit('setLoading', false);
          toastUnableToComplete();
          return Promise.reject(error);
        },
      );
    },
    clearUserScore({ commit }) {
      commit('setUserScore', null);
    },
    clearCompanyScore({ commit }) {
      commit('setCompanyScore', null);
    },
    createFeedbackRequest({ commit }, {
      entityType, entityId, feedbackType, feedbackQuestion, singleResponse, note,
    }) {
      commit('setCreatingFeedback', true);
      return ScoreService.createFeedbackRequest({
        entityType, entityId, feedbackType, feedbackQuestion, singleResponse, note,
      }).then(
        (response) => {
          commit('setCreatingFeedback', false);
          return Promise.resolve(response.data);
        },
        (error) => {
          commit('setCreatingFeedback', false);
          toastFailure('Non è stato possibile creare la richiesta di feedback');
          return Promise.reject(error);
        },
      );
    },
    fetchFeedbackStatistics({ commit }, {
      entityType, entityId, interval, type,
    }) {
      return ScoreService.fetchFeedbackStatistics({
        entityType, entityId, interval, type,
      })
        .then((response) => Promise.resolve(response.data),
          (error) => {
            commit('setLoading', false);
            toastFailure('Non è stato possibile recuperare i dati statistici');
            return Promise.reject(error);
          });
    },
    fetchFeedbackHistoryStatistics({ commit }, {
      entityType, entityId, interval, type,
    }) {
      return ScoreService.fetchFeedbackHistoryStatistics({
        entityType, entityId, interval, type,
      })
        .then((response) => Promise.resolve(response.data),
          (error) => {
            commit('setLoading', false);
            toastFailure('Non è stato possibile recuperare i dati storici');
            return Promise.reject(error);
          });
    },
    searchFeedbackUser({ commit }, {
      search, entityType, entityId, reset, searchName,
    }) {
      commit('setFeedbackUserLoading', { searchName: getSearchName(searchName), loading: true });
      if (reset) {
        commit('clearFeedbackUserSearch');
      }
      return ScoreService.searchOwnFeedback(entityType, entityId, search).then(
        (response) => {
          commit('searchFeedbackUserSuccess', { data: response.data, searchName: getSearchName(searchName) });
          commit('setFeedbackUserLoading', { searchName: getSearchName(searchName), loading: false });
          return Promise.resolve(response);
        },
        (error) => {
          commit('clearFeedbackUserSearch');
          commit('setFeedbackUserLoading', { searchName: getSearchName(searchName), loading: false });
          toastUnableToComplete();
          return Promise.reject(error);
        },
      );
    },
    closeFeedbackUser({ commit }, feedbackName, searchName) {
      commit('setFeedbackUserLoading', { searchName: getSearchName(searchName), loading: true });
      return ScoreService.closeFeedback('person', feedbackName).then(
        (response) => {
          commit('updateFeedbackUserSuccess', { data: response.data, searchName: getSearchName(searchName) });
          commit('setFeedbackUserLoading', { searchName: getSearchName(searchName), loading: false });
          return Promise.resolve(response);
        },
        (error) => {
          commit('setFeedbackUserLoading', { searchName: getSearchName(searchName), loading: false });
          toastUnableToComplete();
          return Promise.reject(error);
        },
      );
    },
    createUserReport({ commit }, createReport, searchName) {
      commit('setFeedbackUserLoading', { searchName: getSearchName(searchName), loading: true });
      return ScoreService.createUserReport(createReport).then(
        (response) => {
          toastSuccess('Segnalazione creata');
          commit('setFeedbackUserLoading', { searchName: getSearchName(searchName), loading: false });
          return Promise.resolve(response.data);
        },
        (error) => {
          commit('setFeedbackUserLoading', { searchName: getSearchName(searchName), loading: false });
          toastUnableToComplete();
          // console.log('eror', error);
          const { data } = error.response;
          return Promise.resolve({ error: true, data });
        },
      );
    },
    clearUserReport({ commit }) {
      commit('setUserReport', null);
    },
    searchUserReports({ commit }, {
      entityType, entityId, search, reset, searchName,
    }) {
      commit('setUserReportLoading', { searchName: getSearchName(searchName), loading: true });
      if (reset) {
        commit('clearFeedbackUserSearch', getSearchName(searchName));
      }
      return ScoreService.searchOwnUserReports(entityType, entityId, search).then(
        (response) => {
          commit('searchUserReportsSuccess', { data: response.data, searchName: getSearchName(searchName) });
          commit('setUserReportLoading', { searchName: getSearchName(searchName), loading: false });
          return Promise.resolve(response);
        },
        (error) => {
          commit('clearUserReportsSearch', getSearchName(searchName));
          commit('setUserReportLoading', { searchName: getSearchName(searchName), loading: false });
          toastUnableToComplete();
          return Promise.reject(error);
        },
      );
    },
    loadUserReport({ commit }, reportName, searchName) {
      commit('setUserReportLoading', { searchName: getSearchName(searchName), loading: true });
      commit('setUserReport', null);
      return ScoreService.loadUserReport(reportName).then(
        (response) => {
          commit('setUserReport', response.data);
          commit('setUserReportLoading', { searchName: getSearchName(searchName), loading: false });
          return Promise.resolve(response.data);
        },
        (error) => {
          commit('setUserReportLoading', { searchName: getSearchName(searchName), loading: false });
          toastUnableToComplete();
          return Promise.reject(error);
        },
      );
    },
    updateStatusReport({ commit }, {
      reportName, userReportId, status, closeComment, searchName, responseCodeId,
    }) {
      commit('setUserReportLoading', { searchName: getSearchName(searchName), loading: true });
      return ScoreService.updateStatusReport({
        reportName, userReportId, status, closeComment, responseCodeId,
      }).then(
        (response) => {
          commit('setUserReport', response.data);
          commit('setUserReportLoading', { searchName: getSearchName(searchName), loading: false });
          return Promise.resolve(response.data);
        },
        (error) => {
          commit('setUserReportLoading', { searchName: getSearchName(searchName), loading: false });
          toastUnableToComplete();
          return Promise.reject(error);
        },
      );
    },
    litigationStatusReport({ commit }, {
      reportName, userReportId, searchName, responseCodeId,
    }) {
      commit('setUserReportLoading', { searchName: getSearchName(searchName), loading: true });
      return ScoreService.litigationStatusReport({
        reportName, userReportId, responseCodeId,
      }).then(
        (response) => {
          commit('setUserReport', response.data);
          commit('setUserReportLoading', { searchName: getSearchName(searchName), loading: false });
          return Promise.resolve(response.data);
        },
        (error) => {
          commit('setUserReportLoading', { searchName: getSearchName(searchName), loading: false });
          toastUnableToComplete();
          return Promise.reject(error);
        },
      );
    },
    fetchReportHistoryStatistics({ commit }, {
      entityType, entityId, interval, type,
    }) {
      return ScoreService.fetchReportHistoryStatistics({
        entityType, entityId, interval, type,
      })
        .then((response) => Promise.resolve(response.data),
          (error) => {
            commit('setLoading', false);
            toastFailure('Non è stato possibile recuperare i dati storici');
            return Promise.reject(error);
          });
    },
    updateUserNote({ commit }, {
      reportName, userReportId, note, searchName,
    }) {
      commit('setUserReportLoading', { searchName: getSearchName(searchName), loading: true });
      return ScoreService.updateUserNote({
        reportName, entityId: userReportId, note,
      }).then(
        (response) => {
          commit('setUserReportNote', response.data);
          commit('setUserReportLoading', { searchName: getSearchName(searchName), loading: false });
          return Promise.resolve(response.data);
        },
        (error) => {
          commit('setUserReportLoading', { searchName: getSearchName(searchName), loading: false });
          toastUnableToComplete();
          return Promise.reject(error);
        },
      );
    },
    searchClub({ commit }, { entityType, taxCode }) {
      commit('setLoadingClub', true);
      return ScoreService.searchClub({ entityType, taxCode }).then(
        (response) => {
          commit('setLoadingClub', false);
          return Promise.resolve(response.data);
        },
        (error) => {
          commit('setLoadingClub', false);
          if (error.status === 409) {
            return Promise.resolve(null);
          }
          // toastUnableToComplete();
          return Promise.reject(error);
        },
      );
    },
    detailClub({ commit }, { entityType, taxCode }) {
      commit('setLoadingClub', true);
      return ScoreService.detailClub({ entityType, taxCode }).then(
        (response) => {
          commit('setLoadingClub', false);
          return Promise.resolve(response.data);
        },
        (error) => {
          commit('setLoadingClub', false);
          if (error.status === 409) {
            return Promise.resolve(null);
          }
          toastUnableToComplete();
          return Promise.reject(error);
        },
      );
    },
  },
  mutations: {
    setLoading(state, loading) {
      state.loading += (loading ? 1 : -1);
    },
    setLoadingClub(state, loading) {
      state.loadingClub = loading;
    },
    setCreatingFeedback(state, creatingFeedback) {
      state.creatingFeedback = creatingFeedback;
    },
    setUserScore(state, scoreData) {
      state.userScore = scoreData;
    },
    setCompanyScore(state, scoreData) {
      state.companyScore = scoreData;
    },
    setFeedbackUserLoading(state, { searchName, loading }) {
      if (isNotExist(state.feedbackUser[searchName])) {
        Vue.set(state.feedbackUser, searchName, { ...emptyList });
      }
      state.feedbackUser[searchName].loading = loading;
    },
    clearFeedbackUserSearch(state, searchName) {
      if (isNotExist(state.feedbackUser[searchName])) {
        Vue.set(state.feedbackUser, searchName, { ...emptyList });
      }
      const current = state.feedbackUser[searchName];
      Vue.set(state.feedbackUser, searchName, {
        ...current,
        content: null,
        pageable: null,
      });
    },
    searchFeedbackUserSuccess(state, { data, searchName }) {
      const { content, ...pageable } = data;
      if (isNotExist(state.feedbackUser[searchName])) {
        Vue.set(state.feedbackUser, searchName, { ...emptyList });
      }

      const current = state.feedbackUser[searchName];
      Vue.set(state.feedbackUser, searchName, { ...current, content, pageable });
    },
    updateFeedbackUserSuccess(state, { data, searchName }) {
      if (isNotExist(state.feedbackUser[searchName])) {
        Vue.set(state.feedbackUser, searchName, { ...emptyList });
      }

      const { content } = state.feedbackUser[searchName];
      if (isArrayNotEmpty(content)) {
        const newList = content.map((item) => {
          if (item.info.feedbackName === data.info.feedbackName) {
            return data;
          }
          return item;
        });
        Vue.set(state.feedbackUser, searchName, { ...state.feedbackUser[searchName], content: newList });
      }
    },
    setUserReport(state, report) {
      state.userReport = report;
    },
    setUserReportLoading(state, { loading, searchName }) {
      if (isNotExist(state.userReports[searchName])) {
        Vue.set(state.userReports, searchName, { ...emptyList });
      }

      state.userReports[searchName].loading = loading;
    },
    clearUserReportsSearch(state, searchName) {
      if (isNotExist(state.userReports[searchName])) {
        Vue.set(state.userReports, searchName, { ...emptyList });
      }

      const current = state.userReports[searchName];
      Vue.set(state.userReports, searchName, {
        ...current,
        content: null,
        pageable: null,
      });
    },
    searchUserReportsSuccess(state, { data, searchName }) {
      const { content, ...pageable } = data;
      if (isNotExist(state.userReports[searchName])) {
        Vue.set(state.userReports, searchName, { ...emptyList });
      }
      const current = state.userReports[searchName];
      Vue.set(state.userReports, searchName, { ...current, content, pageable });
    },
    setUserReportNote(state, { data }) {
      console.log('setUserReportNote', data);
      const { userReport } = state;
      state.userReport = {
        ...userReport,
        litigationReply: data,
      };
    },
  },
};

export default score;
