import Vue from 'vue';
import { isArrayNotEmpty, isNotEmpty, isNotExist } from '@/utils/validators';
import { sortSelectList } from '@/utils/utilities';
import TableUtilsService from '../services/tableUtils.service';

const baseList = {
  loading: false,
  list: null,
};

const initialState = {
  nationList: { ...baseList },
  abiList: { ...baseList },
  mimeIcons: { ...baseList },
  feedbackType: { ...baseList },
  userReportType: { ...baseList },
  productType: { ...baseList },
  privacyList: { ...baseList },
  termsList: { ...baseList },
  tableList: {},
  ateco: {
    loading: false,
    loadingSearch: false,
    errors: false,

    headers: null,
    section: {},
    search: [],
    decoded: null,
  },
  statuses: {
    invoice: {
      DRAFT: {
        theme: 'danger',
        label: 'Fattura',
      },
      INFO_SENT: {
        theme: 'warning',
        label: 'Promemoria inviato',
      },
      REMAINDER_SENT: {
        theme: 'warning',
        label: 'Sollecito',
      },
      REPORT_OPEN: {
        theme: 'warning',
        label: 'In contenzioso',
      },
      CLOSE: {
        theme: 'success',
        label: 'Incassata',
      },
      ABORTED: {
        theme: 'dark',
        label: 'Nota di credito',
      },
      AVAILABLE: {
        theme: 'success',
        label: 'Smobilizzata',
      },
      UNDER_EVALUATION: {
        theme: 'primary',
        label: 'Prenotato',
      },
      BAD_VALUATION: {
        theme: 'danger',
        label: 'A rischio',
      },
      FACTORING: {
        theme: 'success',
        label: 'Factoring',
      },
      ADVANCE_INVOICE: {
        theme: 'success',
        label: 'Anticipo fattura',
      },
      PURCHASED: {
        theme: 'success',
        label: 'Acquistata',
      },
    },
    feedback: {
      OPEN: {
        label: 'Aperto',
        theme: 'warning',
      },
      PUBLIC: {
        label: 'Pubblico',
        theme: 'warning',
      },
      CLOSE: {
        label: 'Chiuso',
        theme: 'danger',
      },
      EXPIRED: {
        label: 'Scaduto',
        theme: 'dark',
      },
      CANCELLED: {
        label: 'Annullato',
        theme: 'danger',
      },
    },
    feedbackResponse: {
      TOBE_CONFIRMED: {
        label: 'Da confermare',
        theme: 'warning',
      },
      CONFIRMED: {
        label: 'Confermata',
        theme: 'success',
      },
      UNDER_CHECK: {
        label: 'In controllo',
        theme: 'warning',
      },
      FAKE: {
        label: 'Falsa',
        theme: 'dark',
      },
      EXPIRED: {
        label: 'Scaduta',
        theme: 'danger',
      },
      REFUSED: {
        label: 'Rifiutata',
        theme: 'danger',
      },
    },
    report: {
      WAIT_ANALYSIS: {
        label: 'Aperta',
        theme: 'warning',
      },
      CONFIRMED: {
        label: 'Confermato',
        theme: 'success',
      },
      UNDER_CHECK: {
        label: 'In elaborazione',
        theme: 'warning',
      },
      FAKE: {
        label: 'Non valida',
        theme: 'dark',
      },
      NOT_CONFIRMED: {
        label: 'Non confermata',
        theme: 'danger',
      },
      EXPIRED: {
        label: 'Scaduto',
        theme: 'black',
      },
      LITIGATION: {
        label: 'Contradditoria',
        theme: 'warning',
      },
      REFUSED: {
        label: 'Rifiutata',
        theme: 'danger',
      },
      USER_CLOSED: {
        label: 'Chiusa da utente',
        theme: 'primary',
      },
      ABORTED: {
        label: 'Annullata',
        theme: 'primary',
      },
    },
    analysis: {
      WAIT_PAYMENT: {
        label: 'Attesa pagamento',
        theme: 'warning',
      },
      IN_PROGRESS: {
        label: 'In lavorazione',
        theme: 'warning',
      },
      COMPLETED: {
        label: 'Completato',
        theme: 'success',
      },
      EXPIRED: {
        label: 'Scaduto',
        theme: 'danger',
      },
      CANCELLED: {
        label: 'Cancellato',
        theme: 'dark',
      },
    },
    walletTransaction: {
      WAITING: {
        label: 'In attesa',
        theme: 'warning',
      },
      CONFIRMED: {
        label: 'Confermato',
        theme: 'success',
      },
      ABORTED: {
        label: 'Cancellato',
        theme: 'danger',
      },
      REFUNDED: {
        label: 'Stornato',
        theme: 'info',
      },
    },
  },
  plafondTypes: [
    {
      key: 'FACTORING',
      label: 'Factoring',
    },
    {
      key: 'ADVANCE_INVOICE',
      label: 'Anticipo fattura',
    },
  ],
};

const INVOICE_PREFIX = 'invoice';

const extractTableList = (table, tableName, prefix) => {
  const name = prefix ? `${prefix}_${tableName}` : tableName;
  return table[name] || { ...baseList };
};

const tableUtils = {
  namespaced: true,
  state: initialState,
  getters: {
    isLoadingNation: (state) => state.nationList.loading,
    getNationList: (state) => state.nationList.list || [],
    hasNationList: (state) => isArrayNotEmpty(state.nationList.list),
    isLoadingAbi: (state) => state.abiList.loading,
    getAbiList: (state) => state.abiList.list || [],
    hasAbiList: (state) => isArrayNotEmpty(state.abiList.list),
    getAbi: (state) => (abi) => {
      const detail = state.abiList.list.find((item) => item.abi === abi);
      if (detail) {
        return detail;
      }

      return {
        abi,
        description: abi,
      };
    },
    isLoadingPrivacy: (state) => state.privacyList.loading,
    getPrivacyList: (state) => state.privacyList.list || [],
    hasPrivacyList: (state) => isNotEmpty(state.privacyList.list),
    isLoadingTerms: (state) => state.termsList.loading,
    getTermsList: (state) => state.termsList.list || [],
    hasTermsList: (state) => isNotEmpty(state.termsList.list),
    isLoadingMimeIcons: (state) => state.mimeIcons.loading,
    getMimeIcons: (state) => state.mimeIcons.list || [],
    hasMimeIcons: (state) => isArrayNotEmpty(state.mimeIcons.list),
    isLoadingFeedbackType: (state) => state.feedbackType.loading,
    getFeedbackType: (state) => state.feedbackType.list || [],
    hasFeedbackType: (state) => isArrayNotEmpty(state.feedbackType.list),
    isLoadingUserReportType: (state) => state.userReportType.loading,
    getUserReportType: (state) => state.userReportType.list || [],
    hasUserReportType: (state) => isArrayNotEmpty(state.userReportType.list),
    isLoadingProductType: (state) => state.productType.loading,
    getProductType: (state) => state.productType.list || [],
    hasProductType: (state) => isArrayNotEmpty(state.productType.list),
    isLoadingTableList: (state) => (tableName) => extractTableList(state.tableList, tableName).loading,
    hasTableList: (state) => (tableName) => isNotEmpty(extractTableList(state.tableList, tableName).list),
    getTableList: (state) => (tableName) => extractTableList(state.tableList, tableName).list,
    isLoadingInvoiceTableList: (state) => (tableName) => extractTableList(state.tableList, tableName, INVOICE_PREFIX).loading,
    hasInvoiceTableList: (state) => (tableName) => isNotEmpty(extractTableList(state.tableList, tableName, INVOICE_PREFIX).list),
    getInvoiceTableList: (state) => (tableName) => extractTableList(state.tableList, tableName, INVOICE_PREFIX).list,
    isLoadingAteco: (state) => state.ateco.loading,
    isLoadingAtecoSearch: (state) => state.ateco.loadingSearch,
    getAtecoHeaders: (state) => state.ateco.headers || [],
    getAtecoSection: (state) => (code) => state.ateco.section[code] || [],
    getAtecoSearchResult: (state) => state.ateco.search || [],
    hasAtecoDecoded: (state) => isNotEmpty(state.ateco.decoded),
    getAtecoDecoded: (state) => state.ateco.decoded || {},
    getStatuses: (state) => (context) => state.statuses[context] || {},
    getStatus: (state) => (context, key) => (state.statuses[context] || {})[key] || {},
    getPlafondTypes: (state) => state.plafondTypes || [],
  },
  actions: {
    loadNationList({ commit }) {
      // console.log('state', this.state);
      commit('setLoading', {
        tableName: 'nationList',
        loading: true,
      });
      return TableUtilsService.nations().then(
        (tableResponse) => {
          commit('tableUtilsSuccess', {
            tableName: 'nationList',
            tableResponse: tableResponse.data,
          });
          commit('setLoading', {
            tableName: 'nationList',
            loading: false,
          });

          return Promise.resolve(tableResponse.data);
        },
        (error) => {
          commit('tableUtilsFailure', 'nationList');
          commit('setLoading', {
            tableName: 'nationList',
            loading: false,
          });

          return Promise.reject(error);
        },
      );
    },
    loadAbiList({ commit }) {
      // console.log('state', this.state);
      commit('setLoading', {
        tableName: 'abiList',
        loading: true,
      });
      return TableUtilsService.abiList().then(
        (tableResponse) => {
          commit('tableUtilsSuccess', {
            tableName: 'abiList',
            tableResponse: tableResponse.data,
          });
          commit('setLoading', {
            tableName: 'abiList',
            loading: false,
          });

          return Promise.resolve(tableResponse.data);
        },
        (error) => {
          commit('tableUtilsFailure', 'abiList');
          commit('setLoading', {
            tableName: 'abiList',
            loading: false,
          });

          return Promise.reject(error);
        },
      );
    },
    loadPrivacyList({ commit }, language) {
      // console.log('state', this.state);
      commit('setLoading', {
        tableName: 'privacyList',
        loading: true,
      });
      return TableUtilsService.privacy(language).then(
        (tableResponse) => {
          commit('tableUtilsSuccess', {
            tableName: 'privacyList',
            tableResponse: tableResponse.data,
          });
          commit('setLoading', {
            tableName: 'privacyList',
            loading: false,
          });

          return Promise.resolve(tableResponse.data);
        },
        (error) => {
          commit('tableUtilsFailure', 'privacyList');
          commit('setLoading', {
            tableName: 'privacyList',
            loading: false,
          });

          return Promise.reject(error);
        },
      );
    },
    loadTermsList({ commit }, language) {
      // console.log('state', this.state);
      commit('setLoading', {
        tableName: 'termsList',
        loading: true,
      });
      return TableUtilsService.terms(language).then(
        (tableResponse) => {
          commit('tableUtilsSuccess', {
            tableName: 'termsList',
            tableResponse: tableResponse.data,
          });
          commit('setLoading', {
            tableName: 'termsList',
            loading: false,
          });

          return Promise.resolve(tableResponse.data);
        },
        (error) => {
          commit('tableUtilsFailure', 'termsList');
          commit('setLoading', {
            tableName: 'termsList',
            loading: false,
          });

          return Promise.reject(error);
        },
      );
    },
    loadMimeIcons({ commit }) {
      // console.log('state', this.state);
      commit('setLoading', {
        tableName: 'mimeIcons',
        loading: true,
      });

      return TableUtilsService.mimeIcons().then(
        (tableResponse) => {
          commit('tableUtilsSuccess', {
            tableName: 'mimeIcons',
            tableResponse: tableResponse.data,
          });
          commit('setLoading', {
            tableName: 'mimeIcons',
            loading: false,
          });
          return Promise.resolve(tableResponse.data);
        },
        (error) => {
          commit('tableUtilsFailure', 'mimeIcons');
          commit('setLoading', {
            tableName: 'mimeIcons',
            loading: false,
          });
          return Promise.reject(error);
        },
      );
    },
    loadFeedbackType({ commit }, language) {
      // console.log('state', this.state);
      commit('setLoading', {
        tableName: 'feedbackType',
        loading: true,
      });

      return TableUtilsService.scoreTables({ tableName: 'feedback-type', language }).then(
        (tableResponse) => {
          commit('tableUtilsSuccess', {
            tableName: 'feedbackType',
            tableResponse: tableResponse.data,
          });
          commit('setLoading', {
            tableName: 'feedbackType',
            loading: false,
          });
          return Promise.resolve(tableResponse.data);
        },
        (error) => {
          commit('tableUtilsFailure', 'feedback-type');
          commit('setLoading', {
            tableName: 'feedbackType',
            loading: false,
          });
          return Promise.reject(error);
        },
      );
    },
    loadUserReportType({ commit }, language) {
      // console.log('state', this.state);
      commit('setLoading', {
        tableName: 'userReportType',
        loading: true,
      });

      return TableUtilsService.scoreTables({ tableName: 'user_report_type', language }).then(
        (tableResponse) => {
          commit('tableUtilsSuccess', {
            tableName: 'userReportType',
            tableResponse: tableResponse.data,
          });
          commit('setLoading', {
            tableName: 'userReportType',
            loading: false,
          });
          return Promise.resolve(tableResponse.data);
        },
        (error) => {
          commit('tableUtilsFailure', 'user_report_type');
          commit('setLoading', {
            tableName: 'userReportType',
            loading: false,
          });
          return Promise.reject(error);
        },
      );
    },
    loadProductType({ commit }, language) {
      // console.log('state', this.state);
      commit('setLoading', {
        tableName: 'productType',
        loading: true,
      });

      return TableUtilsService.scoreTables({ tableName: 'product_type', language }).then(
        (tableResponse) => {
          commit('tableUtilsSuccess', {
            tableName: 'productType',
            tableResponse: tableResponse.data,
          });
          commit('setLoading', {
            tableName: 'productType',
            loading: false,
          });
          return Promise.resolve(tableResponse.data);
        },
        (error) => {
          commit('tableUtilsFailure', 'productType');
          commit('setLoading', {
            tableName: 'productType',
            loading: false,
          });
          return Promise.reject(error);
        },
      );
    },
    loadFeedbackResponses({ commit }, { feedbackType, language }) {
      // console.log('state', this.state);
      commit('setLoadingTableList', {
        tableName: feedbackType,
        loading: true,
      });

      return TableUtilsService.scoreTables({ tableName: feedbackType, language }).then(
        (tableResponse) => {
          commit('tableListUtilsSuccess', {
            tableName: feedbackType,
            tableResponse: tableResponse.data,
          });
          commit('setLoadingTableList', {
            tableName: feedbackType,
            loading: false,
          });

          return Promise.resolve(tableResponse.data);
        },
        (error) => {
          commit('tableListUtilsFailure', feedbackType);
          commit('setLoadingTableList', {
            tableName: feedbackType,
            loading: false,
          });

          return Promise.reject(error);
        },
      );
    },
    loadTableList({ commit }, { tableName, language }) {
      // console.log('state', this.state);
      commit('setLoadingTableList', {
        tableName,
        loading: true,
      });

      return TableUtilsService.scoreTables({ tableName, language }).then(
        (tableResponse) => {
          console.log('tableResponse', tableResponse);
          if (tableResponse.status === 200) {
            commit('tableListUtilsSuccess', {
              tableName,
              tableResponse: tableResponse.data,
            });
            commit('setLoadingTableList', {
              tableName,
              loading: false,
            });

            return Promise.resolve(tableResponse.data);
          }
          commit('tableListUtilsFailure', tableName);
          commit('setLoadingTableList', {
            tableName,
            loading: false,
          });
          return Promise.resolve([]);
        },
        (error) => {
          commit('tableListUtilsFailure', tableName);
          commit('setLoadingTableList', {
            tableName,
            loading: false,
          });

          return Promise.reject(error);
        },
      ).catch((error) => {
        // console.log('tablelist failed', error);
        commit('tableListUtilsFailure', tableName);
        commit('setLoadingTableList', {
          tableName,
          loading: false,
        });

        return Promise.resolve(error);
      });
    },

    loadAtecoHeaders({ commit }) {
      commit('setAtecoLoading', true);
      return TableUtilsService.atecoHeaders().then(
        (tableResponse) => {
          commit('setAtecoHeaders', tableResponse.data);
          commit('setAtecoLoading', false);
          return Promise.resolve(tableResponse.data);
        },
        (error) => {
          commit('setAtecoHeaders', null);
          commit('setAtecoLoading', false);

          return Promise.reject(error);
        },
      );
    },
    loadAtecoSection({ commit }, code) {
      commit('setAtecoLoading', true);
      return TableUtilsService.atecoSection(code).then(
        (tableResponse) => {
          commit('setAtecoSection', { sectionValues: tableResponse.data, code });
          commit('setAtecoLoading', false);
          return Promise.resolve(tableResponse.data);
        },
        (error) => {
          commit('setAtecoSection', { sectionValues: null, code });
          commit('setAtecoLoading', false);

          return Promise.reject(error);
        },
      );
    },
    searchAteco({ commit }, code) {
      commit('setAtecoLoadingSearch', true);
      return TableUtilsService.atecoSearch(code).then(
        (tableResponse) => {
          commit('setAtecoSearch', tableResponse.data);
          commit('setAtecoLoadingSearch', false);
          return Promise.resolve(tableResponse.data);
        },
        (error) => {
          commit('setAtecoSearch', []);
          commit('setAtecoLoadingSearch', false);

          return Promise.reject(error);
        },
      );
    },
    decodeAteco({ commit }, code) {
      commit('setAtecoLoading', true);
      return TableUtilsService.atecoDecode(code).then(
        (tableResponse) => {
          commit('setAtecoDecode', tableResponse.data);
          commit('setAtecoLoading', false);
          return Promise.resolve(tableResponse.data);
        },
        (error) => {
          commit('setAtecoDecode', null);
          commit('setAtecoLoading', false);

          return Promise.reject(error);
        },
      );
    },
    clearDecodeAteco({ commit }) {
      commit('setAtecoDecode', null);
    },
    clearAteco({ commit }) {
      commit('setAtecoDecode', null);
      commit('setAtecoSearch', []);
    },
    loadInvoiceTableList({ commit }, tableName) {
      // console.log('state', this.state);
      const type = `${INVOICE_PREFIX}_${tableName}`;
      commit('setLoading', {
        tableName: type,
        loading: true,
      });
      return TableUtilsService.invoiceTableList(tableName).then(
        (tableResponse) => {
          commit('tableUtilsSuccess', {
            tableName: type,
            tableResponse: tableResponse.data,
          });
          commit('setLoading', {
            tableName: type,
            loading: false,
          });

          return Promise.resolve(tableResponse.data);
        },
        (error) => {
          commit('tableUtilsFailure', type);
          commit('setLoading', {
            tableName: type,
            loading: false,
          });

          return Promise.reject(error);
        },
      );
    },
  },
  mutations: {
    setLoading(state, { tableName, loading }) {
      state[tableName].loading = loading;
    },
    setLoadingTableList(state, { tableName, loading }) {
      if (isNotExist(state.tableList[tableName])) {
        Vue.set(state.tableList, tableName, { ...baseList });
      }
      const table = state.tableList[tableName];
      table.loading = loading;
      Vue.set(state.tableList, tableName, { ...table });
    },
    tableUtilsSuccess(state, { tableName, tableResponse }) {
      // console.log('tableName', tableName, tableResponse);
      if (isNotExist(state[tableName])) {
        Vue.set(state, tableName, { ...baseList });
      }
      const table = state[tableName];
      table.list = tableResponse;
      Vue.set(state, tableName, { ...table });
    },
    tableUtilsFailure(state, tableName) {
      console.log('tableUtilsFailure', tableName);
      // state[tableName].loading = false;
    },
    tableListUtilsSuccess(state, { tableName, tableResponse }) {
      // console.log('tableName', tableName, tableResponse);
      if (isNotExist(state.tableList[tableName])) {
        Vue.set(state.tableList, tableName, { ...baseList });
      }
      const table = state.tableList[tableName];
      table.list = tableResponse;
      Vue.set(state.tableList, tableName, { ...table });
    },
    tableListUtilsFailure(state, tableName) {
      // console.log('tableUtilsFailure');
      if (isNotExist(state.tableList[tableName])) {
        Vue.set(state.tableList, tableName, { ...baseList });
      }
      state.tableList[tableName].loading = false;
    },
    setAtecoLoading(state, loading) {
      const { ateco } = state;
      state.ateco = { ...ateco, loading };
    },
    setAtecoLoadingSearch(state, loadingSearch) {
      const { ateco } = state;
      state.ateco = { ...ateco, loadingSearch };
    },
    setAtecoHeaders(state, atecoHeaders) {
      const { ateco } = state;
      state.ateco = { ...ateco, headers: sortSelectList(atecoHeaders) };
    },
    setAtecoSection(state, { code, sectionValues }) {
      if (isNotExist(state.ateco.section[code])) {
        Vue.set(state.ateco.section, code, []);
      }
      const { ateco } = state;
      const { section } = ateco;
      state.ateco = { ...ateco, section: { ...section, [code]: sortSelectList(sectionValues) } };
    },
    setAtecoDecode(state, atecoDecode) {
      const { ateco } = state;
      state.ateco = { ...ateco, decoded: atecoDecode };
    },
    setAtecoSearch(state, atecoSearch) {
      const { ateco } = state;
      state.ateco = { ...ateco, search: atecoSearch };
    },
  },
};

export default tableUtils;
