import SendmailService from '@/services/sendmail.service';
import { toastSuccess, toastUnableToComplete } from '@/utils/toast';
import { isNotEmpty } from '@/utils/validators';

const initialState = {
  loading: false,
  previewMail: null,
};

const sendmail = {
  namespaced: true,
  state: initialState,
  getters: {
    isLoading: (state) => state.loading,
    hasPreview: (state) => isNotEmpty(state.previewMail),
    getPreview: (state) => state.previewMail || {},
  },
  actions: {
    sendMailRequest({ commit }, {
      entityId, language, sendMail, showPreview,
    }) {
      commit('setLoading', true);
      commit('setPreview', null);
      SendmailService.sendMailRequest({ entityId, language, sendMail })
        .then((response) => {
          commit('setLoading', false);
          if (response.status >= 200 && response.status <= 299) {
            this.submitted = true;
            if (!showPreview) {
              toastSuccess('Mail inviata');
            }
            return Promise.resolve(true);
          }
          toastUnableToComplete();
          return Promise.resolve(false);
        }).catch((error) => {
          console.warn('Unable to complete email request', error);
          commit('setLoading', false);
          toastUnableToComplete();
          Promise.reject(error);
        });
    },
    sendUpdateMail({ commit, state }, {
      subject,
      htmlBody,
      toList,
      ccList,
      bccList,
    }) {
      commit('setLoading', true);
      const { entityId, message } = state.previewMail;
      SendmailService.sendUpdateMail({
        message: { ...message, body: htmlBody, subject },
        toList: toList.map((email) => ({ destinationEmail: email })),
        ccList: ccList.map((email) => ({ destinationEmail: email })),
        bccList: bccList.map((email) => ({ destinationEmail: email })),
        processId: entityId,
      })
        .then((response) => {
          commit('setLoading', false);
          if (response.status >= 200 && response.status <= 299) {
            toastSuccess('Mail inviata');
            return Promise.resolve(true);
          }
          toastUnableToComplete();
          return Promise.resolve(false);
        }).catch((error) => {
          console.warn('Unable to complete email request', error);
          commit('setLoading', false);
          toastUnableToComplete();
          Promise.reject(error);
        });
    },
    processMessagePreviewEmail({ commit }, message) {
      const { command, data, entityId } = message;
      if (command === 'PREVIEW-EMAIL') {
        commit('setPreview', { ...data, entityId });
      }
      return Promise.resolve();
    },
  },
  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },
    setPreview(state, preview) {
      state.previewMail = preview;
    },

  },
};

export default sendmail;
