import { request } from '@/utils/request';

const apiBase = '/crp-invoice-api';

class InvoiceService {
  invoicesList = ({
    context,
    companyId,
    search,
    page,
    size,
    sort,
    ...extraFilters
  }) => {
    const requestPage = page || 0;
    const requestSize = size || 10;
    const requestSort = sort || [];
    const requestSearch = search || '';
    const currentContext = context || 'default';

    return request(`/own-invoices/${companyId}/${currentContext}`, false, {
      method: 'post',
      data: {
        search: requestSearch,
        page: requestPage,
        size: requestSize,
        sort: requestSort,
        ...extraFilters,
      },
      apiBase,
    });
  };

  invoicesFilterTotal = ({
    context,
    companyId,
    search,
    page,
    size,
    sort,
    ...extraFilters
  }) => {
    const requestPage = page || 0;
    const requestSize = size || 10;
    const requestSort = sort || [];
    const requestSearch = search || '';
    const currentContext = context || 'default';

    return request(`/stats/invoices/${companyId}/${currentContext}`, false, {
      method: 'post',
      data: {
        search: requestSearch,
        page: requestPage,
        size: requestSize,
        sort: requestSort,
        ...extraFilters,
      },
      apiBase,
    });
  };

  loadInvoice = (invoiceId) => request(`/invoice/${invoiceId}`, false, { apiBase });

  checkStatusInvoice = (invoiceId) => request(`/invoice-update-status/${invoiceId}`, false, { apiBase });

  updateStatusInvoice = ({ invoiceId, status, noteStatus }) => request('/invoice-update-status', false, {
    apiBase,
    method: 'post',
    data: {
      invoiceId,
      status,
      noteStatus,
    },
  });

  updateScoringCrpStatus = (invoiceId) => request(`/invoice-refresh-rating/${invoiceId}`, false, { apiBase });

  deleteInvoice = (invoiceId) => request(`/invoice/${invoiceId}`, false, {
    method: 'delete',
    apiBase,
  });

  plafondsList = ({
    companyId,
    search,
    page,
    size,
    sort,
    ...extraFilters
  }) => {
    const requestPage = page || 0;
    const requestSize = size || 10;
    const requestSort = sort || [];
    const requestSearch = search || '';

    return request(`/own-plafonds/${companyId}`, false, {
      method: 'post',
      data: {
        search: requestSearch,
        page: requestPage,
        size: requestSize,
        sort: requestSort,
        ...extraFilters,
      },
      apiBase,
    });
  };

  loadPlafond = (plafondId) => request(`/plafond/${plafondId}`, false, { apiBase });

  savePlafond = (plafond) => {
    const method = plafond.plafondId ? 'put' : 'post';
    return request('/own-plafond', false, {
      method,
      data: {
        ...plafond,
      },
      apiBase,
    });
  }

  deletePlafond = (plafondId) => request(`/plafond/${plafondId}`, false, {
    method: 'delete',
    apiBase,
  });

  plafondsUsage = (companyId) => request(`/plafond-usage/${companyId}`, false, {
    apiBase,
  });

  verifyUploadPaymentInvoice = (uploadPaymentCode) => request(`/invoice-upload/${uploadPaymentCode}`, true, {
    method: 'get',
    apiBase,
  });
}

export default new InvoiceService();
