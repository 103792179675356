// import request from '@/utils/request';

import { request } from '@/utils/request';

const apiBase = '/backoffice-api';
const apiScoreBase = '/crp-api';
const apiInvoiceBase = '/crp-invoice-api';

class TableUtilsService {
  nations = () => request('nation/list', false, { apiBase });

  terms = (language) => request(`terms-conditions/${language}`, true, { apiBase });

  privacy = (language) => request(`privacy-flags/${language}`, true, { apiBase });

  mimeIcons = () => request('mime-icons', false, { apiBase });

  scoreTables = ({ tableName, language }) => request(`/table/${tableName}/${language}`,
    false, { apiBase: apiScoreBase, processFailed: true });

  atecoHeaders = () => request('/ateco/headers', false, { apiBase });

  atecoSection = (code) => request(`/ateco/sections/${code}`, false, { apiBase });

  atecoSearch = (code) => request(`/ateco/search/${code}`, false, { apiBase });

  atecoDecode = (code) => request(`/ateco/decode/${code}`, false, { apiBase });

  invoiceTableList = (type) => request(`list-invoice/${type}`, false, { apiBase: apiInvoiceBase });

  searchCab = ({ abi, search }) => request(`/list-bank/${abi}/${search}`, false, { apiBase: apiInvoiceBase });

  abiList = () => request('/list-bank/abi', false, { apiBase: apiInvoiceBase });
}

export default new TableUtilsService();
