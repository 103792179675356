import gql from 'graphql-tag';

const query = gql`query companyQuery ($taxCode: String!){
     company(taxCode: $taxCode) {
       info {
         companyName
         vatNumber
         taxCode
         legalCompanyType
       }
       attribute {
         companyCreationDate
         registrationNumber
         cciaaRegistrationDate
         startActivityDate
         mainPecEmail{
           contactData
         }
         atecoCode
       }
       atecoInfo {
         description
       }
       score {
         totalPoint
         reportReceived{
           globalVotes{
             countPositive
             countNegative
             countTotal
           }
         }
       }
       legal {
         personInfo{
           name
           surname
           email
         }
       }
       legalAddress {
         streetName
         streetNumber
         city
         province
         zip
         countryCode
       }
       addressList {
        streetName
        streetNumber
        city
        province
        countryCode
       }
       scoring{
         year
         scoringLabel
         scoringEquivalent
         creditLimit
         capitalisationTotal{
           point
         }
         profitabilityTotal{
           point
         }
         liquidityTotal{
           point
         }
         sectorTotal{
           point
         }
         crPayTotal{
           point
         }
       }
       reportFull{
         status
         documentId
       }
     }
   }
   `;

export default query;
