<template>
  <user-structure
    hide-button
    hide-switch
    hasCreateReport
    :title="headingText"
    is-tabbed
    :title-button="{
      'content': 'Effettua una segnalazione',
      'route': 'create-report',
      'visibility': isOperative,
    }"
  >
    <template #body>
      <div>
        <tab-report-history :hasScoreData="hasScoreData" />
      </div>
    </template>
  </user-structure>
</template>

<script>
import { isNotEmpty } from '../utils/validators';

const UserStructure = () => import('@/components/layout/UserStructure.vue');
const TabReportHistory = () => import('@/components/userSections/TabReportHistory.vue');

export default {
  name: 'UserReportHistory',
  components: {
    TabReportHistory,
    UserStructure,
  },
  data() {
    return {
      hasLoaded: false,
    };
  },
  computed: {
    isCompanyProfile() {
      const currentProfile = this.$store.getters['subject/currentCompany'];
      return isNotEmpty(currentProfile);
    },
    hasScoreData() {
      if (this.hasLoaded !== this.$store.getters['score/hasCurrentScore']) {
        this.changePreloaderStatus(this.$store.getters['score/hasCurrentScore']);
      }
      return this.$store.getters['score/hasCurrentScore'];
    },
    headingText() {
      if (this.isCompanyProfile) return 'Segnalazioni & Feedback';
      return 'Elenco segnalazioni';
    },
    shoutBoxData() {
      return {
        title: this.headingText,
      };
    },
    activityStatus() {
      if (this.isCompanyProfile) {
        return this.$store.getters['subject/activityCompanyStatus'];
      }
      return this.$store.getters['subject/activityPersonStatus'];
    },
    isOperative() {
      return this.activityStatus.operative;
    },
  },
  metaInfo() {
    return {
      title: `${this.headingText} - CR Pagamenti`,
      meta: [
        { property: 'og:title', content: `${this.headingText} - CR Pagamenti` },
        { property: 'og:site_name', content: 'CR Pagamenti' },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'noindex,nofollow' },
      ],
    };
  },
  methods: {
    changePreloaderStatus(state) {
      this.hasLoaded = state;
      console.debug('UserReportHistory setpreloader', !state);
      this.$store.dispatch('setPreloader', !state);
    },
  },
};
</script>

<style scoped>

</style>
