<template>
  <user-structure
    hide-button
    hide-switch
    :title="pageTitle"
  >
    <template #body>
      <cdv-search-data
        v-if="isOperative"
      />
<!--      <div :class="historyClasses">-->
<!--        <cdv-search-history />-->
<!--      </div>-->
    </template>

  </user-structure>
</template>

<script>
import { isNotEmpty } from '@/utils/validators';

const UserStructure = () => import('@/components/layout/UserStructure.vue');
const CdvSearchData = () => import('../components/cdvSearch/CdvSearchData.vue');
// const CdvSearchHistory = () => import('@/components/cdvSearch/CdvSearchHistory.vue');

export default {
  name: 'UserCdvSearch',
  // components: { CdvSearchHistory, CdvSearchData, UserStructure },
  components: { CdvSearchData, UserStructure },
  data() {
    return {
      entityType: null,
      taxCode: null,
      profileData: {},
    };
  },
  computed: {
    pageTitle() {
      return this.isOperative ? 'Club dei Virtuosi' : null;
    },
    isCompanyProfile() {
      const currentProfile = this.$store.getters['subject/currentCompany'];
      return isNotEmpty(currentProfile);
    },
    activityStatus() {
      if (this.isCompanyProfile) {
        return this.$store.getters['subject/activityCompanyStatus'];
      }
      return this.$store.getters['subject/activityPersonStatus'];
    },
    isOperative() {
      return this.activityStatus.operative;
    },
    // historyClasses() {
    //   return this.isOperative ? 'mt-5' : 'mt-4';
    // },
  },
  metaInfo() {
    return {
      title: `${this.pageTitle} - CR Pagamenti`,
      meta: [
        { property: 'og:title', content: 'Ricerca Profilo CRP - CR Pagamenti' },
        { property: 'og:site_name', content: 'CR Pagamenti' },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'noindex,nofollow' },
      ],
    };
  },
};
</script>
