import { isNotExist } from '@/utils/validators';

export const keyStorage = 'user-crp';

class TokenService {
  getLocalRefreshToken = () => {
    const user = this.getUser();
    return user?.refresh_token;
  }

  getLocalAccessToken = () => {
    const user = this.getUser();
    // console.log('getLocalAccessToken', user);
    return user?.access_token;
  }

  updateLocalAccessToken = (token) => {
    const user = this.getUser();
    user.access_token = token;
    localStorage.setItem(keyStorage, JSON.stringify(user));
  }

  getUser = () => JSON.parse(localStorage.getItem(keyStorage))

  setUser = (user) => {
    // console.log(JSON.stringify(user));
    localStorage.setItem(keyStorage, JSON.stringify(user));
  }

  removeUser = () => {
    localStorage.removeItem(keyStorage);
  }

  isNotLogged = () => isNotExist(this.getUser())

  authHeader = () => {
    const user = this.getUser();
    if (user && user.access_token) {
      return { Authorization: `Bearer ${user.access_token}` }; // for Spring Boot back-end
    // return { 'x-access-token': user.accessToken };       // for Node.js Express back-end
    }
    return {};
  }
}

export default new TokenService();
