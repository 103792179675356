/* eslint-disable no-underscore-dangle */
import axiosInstance from './api';
import TokenService from './token.service';

const setup = (store, router) => {
  const ignoredPath = ['/login', '/logout', '/refresh', '/capture', '/order/create'];
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = TokenService.getLocalAccessToken();
      console.log('axios inteceptor', config);
      const publicApi = (config.baseURL || '').indexOf('/public/') !== -1;
      if (token && !publicApi) {
        // eslint-disable-next-line no-param-reassign,dot-notation
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error),
  );

  axiosInstance.interceptors.response.use(
    (res) => res,
    async (err) => {
      const originalConfig = err.config;
      // console.log('response error', originalConfig.url, ignoredPath.indexOf(originalConfig.url));
      // console.log('response error route', router.currentRoute);

      const invalidResponse = [401];
      if (invalidResponse.indexOf(err.response.status) === -1) {
        return Promise.reject(err);
      }
      if (ignoredPath.indexOf(originalConfig.url) === -1 && err.response) {
        // Access Token was expired
        if (invalidResponse.indexOf(err.response.status) !== -1 && !originalConfig._retry) {
          originalConfig._retry = true;
          await store.dispatch('auth/setPath', router.currentRoute.path);

          try {
            await store.dispatch('auth/refreshToken');
            // const rs = await axiosInstance.post('/auth/refreshtoken', {
            //   refreshToken: TokenService.getLocalRefreshToken(),
            // });
            //
            // const { accessToken } = rs.data;
            //
            // store.dispatch('auth/refreshToken', accessToken);
            // TokenService.updateLocalAccessToken(accessToken);

            return axiosInstance(originalConfig);
          } catch (_error) {
            // return Promise.reject(_error);
            await store.dispatch('auth/cleanSession');
            await store.dispatch('subject/logout');
            return router.replace('/accedi');
          }
        }
      }

      // return Promise.reject(err);
      await store.dispatch('auth/cleanSession');
      await store.dispatch('subject/logout');
      return router.replace('/');
    },
  );
};

export default setup;
