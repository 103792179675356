import { isEmpty } from 'lodash';
import SubjectService from '@/services/subject.service';

const searchCompany = (search, customFilter) => new Promise((resolve) => {
  if (isEmpty(search)) {
    return resolve([]);
  }
  const companies = SubjectService
    .companiesList({
      search,
      page: 0,
      size: 20,
      sort: [{ field: 'info.companyName', direction: 'ASC' }],
      filters: [
        {
          field: 'investor',
          operation: 'NE',
          value: true,
        },
      ],
      customFilter,
    })
    .then((response) => response.data.content.map((item) => ({
      value: item.companyId,
      label: `${item.info.companyName} (${item.info.vatNumber})`,
      company: item,
    })))
  // eslint-disable-next-line no-unused-vars
    .catch((error) => []);
  return resolve(companies);
});

const searchPerson = (search) => new Promise((resolve) => {
  if (isEmpty(search)) {
    return resolve([]);
  }
  const persons = SubjectService
    .personsList({
      search,
      page: 0,
      size: 20,
      sort: [{ field: 'personInfo.surname', direction: 'ASC' }, { field: 'personInfo.name', direction: 'ASC' }],
      filters: [
        {
          field: 'legalCompany',
          operation: 'NE',
          value: true,
        },
      ],
    })
    .then((response) => response.data.content.map((item) => ({
      value: item.personId,
      label: `${item.personInfo.surname} ${item.personInfo.name} (${item.anagraficalInfo.taxCode})`,
      person: item,
    })))
  // eslint-disable-next-line no-unused-vars
    .catch((error) => []);
  return resolve(persons);
});

export {
  searchCompany, searchPerson,
};
