<template>
  <user-structure
    hide-button
    hide-switch
    hasCreateReport
    :title="this.reportName"
    has-back
  >
    <template #body>
      <loader-info v-if="loadingReport" />
      <div v-else>
        <div v-if="!showDetail">
          Segnalazione {{reportName}} non trovata
        </div>
        <user-report-detail-info v-if="showDetail" :user-report="userReport" />
      </div>
    </template>
  </user-structure>
</template>

<script>
import { isNotEmpty } from '@/utils/validators';

const UserStructure = () => import('@/components/layout/UserStructure.vue');
const UserReportDetailInfo = () => import('@/components/userReports/UserReportDetailInfo.vue');
const LoaderInfo = () => import('@/components/helpers/LoaderInfo.vue');

export default {
  name: 'UserReportDetail',
  components: {
    UserReportDetailInfo,
    UserStructure,
    LoaderInfo,
  },
  data() {
    return {
      hasLoaded: false,
      loadingReport: false,
    };
  },
  computed: {
    reportName() {
      return this.$route.params.reportName;
    },
    hasScoreData() {
      if (this.hasLoaded !== this.$store.getters['score/hasCurrentScore']) {
        this.changePreloaderStatus(this.$store.getters['score/hasCurrentScore']);
      }
      return this.$store.getters['score/hasCurrentScore'];
    },
    userReport() {
      return this.$store.getters['score/getCurrentUserReport'];
    },
    showDetail() {
      return isNotEmpty(this.userReport);
    },
  },
  metaInfo() {
    return {
      title: 'Report - CR Pagamenti',
      meta: [
        { property: 'og:title', content: 'Report - CR Pagamenti' },
        { property: 'og:site_name', content: 'CR Pagamenti' },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'index,follow' },
      ],
    };
  },
  mounted() {
    this.loadReport();
  },
  methods: {
    loadReport() {
      this.loadingReport = true;
      this.$store.dispatch('score/loadUserReport', this.$route.params.reportName).then(() => {
        // console.log('loaded');
        this.loadingReport = false;
      },
      () => {
        // console.log('loading failed', this.$route.name);
        this.loadingReport = false;
      });
    },
    changePreloaderStatus(state) {
      this.hasLoaded = state;
      console.debug('UserReportDetail setpreloader', !state);
      this.$store.dispatch('setPreloader', !state);
    },
  },
};
</script>

<style scoped>

</style>
