import { request } from '@/utils/request';

const apiBase = '/crp-api';

class AnalysisService {
  createUserAnalysis = (createAnalysis) => request('/user/analysis/create', false,
    {
      method: 'post',
      data: {
        ...createAnalysis,
      },
      apiBase,
    });

  searchOwnUserAnalysis = (entityType, entityId, search) => request(`/user-analysis/search/${entityType}/${entityId}`, false,
    {
      method: 'post',
      data: {
        ...search,
      },
      apiBase,
    });

  exportOwnUserAnalysis = (entityType, entityId, search) => request(`/user-analysis/export/${entityType}/${entityId}`, false,
    {
      method: 'post',
      data: {
        ...search,
      },
      apiBase,
    });

  loadUserAnalysis = (analysisName) => request(`/user-analysis/${analysisName}`, false, { method: 'get', apiBase });

  loadBalanceAvailableList = (entityType,
    entityId,
    taxCode,
    productConnectType,
    productConnectCode) => request(`/bilanci/ricerca/${entityType}/${entityId}`, false,
    {
      method: 'post',
      data: {
        taxCode, productConnectType, productConnectCode,
      },
      apiBase,
    });
}

export default new AnalysisService();
