<template>
  <user-structure
    hide-button
    hide-switch
    :title="headingText"
  >
    <template #body>
      <div>
        <cr-invoice-tabs />
      </div>
    </template>
  </user-structure>
</template>

<script>

import { isNotEmpty } from '../utils/validators';

const UserStructure = () => import('@/components/layout/UserStructure.vue');
const CrInvoiceTabs = () => import('@/components/crInvoice/CrInvoiceTabs.vue');

export default {
  name: 'UserCRInvoice',
  components: {
    CrInvoiceTabs,
    UserStructure,
  },
  computed: {
    headingText() {
      return 'CR Invoice';
    },
    isCompanyProfile() {
      const currentProfile = this.$store.getters['subject/currentCompany'];
      return isNotEmpty(currentProfile);
    },
  },
  metaInfo() {
    return {
      title: 'CR Invoice - CR Pagamenti',
      meta: [
        { property: 'og:title', content: 'CR Invoice - CR Pagamenti' },
        { property: 'og:site_name', content: 'CR Pagamenti' },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'index,follow' },
      ],
    };
  },
  mounted() {
    if (!this.isCompanyProfile) {
      this.$router.replace('/dashboard-utente');
    }
  },
  methods: {},
};
</script>

<style scoped>

</style>
