import { isPresent } from '@/utils/validators';

export const decodeAddress = (place) => {
  const address = {
    countryCode: null,
    streetNumber: null,
    streetName: null,
    zip: null,
    city: null,
    province: null,
    state: null,
    formatted: null,
    contactList: [],
    type: null,
  };

  if (place === null || place === undefined) {
    return null;
  }

  address.type = place.type;
  address.streetName = place.streetName;
  address.city = place.city;
  address.province = place.province;
  address.state = place.province;
  // address.state = place.state;
  if (place.countryCode && place.countryCode.value) {
    address.countryCode = place.countryCode.value;
  } else {
    address.countryCode = place.countryCode;
  }
  address.zip = place.zip;
  address.formatted = `${address.streetName}, ${
    address.zip
  } ${address.city} ${address.province}, ${address.countryCode}`;
  return address;
};

export const checkAddressError = (address, isSlim) => {
  if (address === undefined || address === null) {
    return {
      nation: 'La nazione è obbligatoria',
      city: 'La città è obbligatoria',
      zip: 'Il CAP è obbligatorio',
      street: "L'indirizzo è obbligatorio",
    };
  }
  const addrError = {};
  let hasError = false;
  if (!isPresent(address.countryCode)) {
    addrError.nation = 'La nazione è obbligatoria';
    hasError = true;
  }
  if (!isPresent(address.city)) {
    addrError.city = 'La città è obbligatoria';
    hasError = true;
  }
  if (!isPresent(address.province)) {
    addrError.province = 'La provincia è obbligatoria';
    hasError = true;
  }
  if (!isSlim) {
    if (!isPresent(address.zip)) {
      addrError.zip = 'Il CAP è obbligatorio';
      hasError = true;
    }
    if (!isPresent(address.streetName)) {
      addrError.streetName = "L'indirizzo è obbligatorio";
      hasError = true;
    }
  }

  if (hasError) return addrError;

  return null;
};
