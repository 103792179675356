import Vue from 'vue';
import VueRouter from 'vue-router';
import ValidateToken from '@/views/ValidateToken.vue';
import Register from '@/views/Register.vue';
import Home from '@/views/Home.vue';
import UserDashboard from '@/views/UserDashboard.vue';
import Login from '@/views/Login.vue';
import UserCreateReport from '@/views/UserCreateReport.vue';
import UserReportHistory from '@/views/UserReportHistory.vue';
import UserReportDetail from '@/views/UserReportDetail.vue';
import UserAnalysisRequest from '@/views/UserAnalysisRequest.vue';
import UserAnalysisHistory from '@/views/UserAnalysisHistory.vue';
import UserAnalysisDetail from '@/views/UserAnalysisDetail.vue';
import UserStats from '@/views/UserStats.vue';
import UserData from '@/views/UserData.vue';
import RecoverPassword from '@/views/RecoverPassword.vue';
import UserWallet from '@/views/UserWallet.vue';
import UserWalletTopup from '@/views/UserWalletTopup.vue';
import UserCRInvoice from '@/views/UserCRInvoice.vue';
// import UserClubPerson from '@/views/UserClubPerson.vue';
import UserClubCompany from '@/views/UserClubCompany.vue';
import UserViewProfile from '@/views/UserViewProfile.vue';
import UserInvoiceDetail from '@/views/UserInvoiceDetail.vue';
import UserProductList from '@/views/UserProductList.vue';
import UserProfileSearch from '@/views/UserProfileSearch.vue';
import ErrorPage from '@/views/ErrorPage.vue';
import UploadRequest from '@/views/UploadRequest.vue';
import UserCdvSearch from '@/views/UserCdvSearch.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/registrati',
    name: 'Register',
    component: Register,
  },
  {
    path: '/accedi',
    name: 'Login',
    component: Login,
  },
  {
    path: '/dashboard-utente',
    name: 'Dashboard Utente',
    component: UserDashboard,
  },
  {
    path: '/user-report-history',
    name: 'Segnalazioni',
    component: UserReportHistory,
  },
  {
    path: '/user-report-detail/:reportName',
    name: 'Dettaglio Segnalazione',
    component: UserReportDetail,
  },
  {
    path: '/validate-user/:token',
    name: 'ValidateToken',
    component: ValidateToken,
  },
  {
    path: '/create-company-report',
    name: "Segnala un'azienda",
    component: UserCreateReport,
    props: {
      requestType: 'company',
    },
  },
  {
    path: '/create-person-report',
    name: 'Segnala una persona',
    component: UserCreateReport,
    props: {
      requestType: 'person',
    },
  },
  {
    path: '/create-report',
    name: 'Segnalazioni',
    component: UserCreateReport,
    props: {
      requestType: '',
    },
  },
  {
    path: '/request-analysis',
    name: 'Richiedi analisi',
    component: UserAnalysisRequest,
  },
  {
    path: '/user-analysis-history',
    name: 'Richiest',
    component: UserAnalysisHistory,
  },
  {
    path: '/user-analysis-detail/:requestName',
    name: 'Dettaglio Segnalazione',
    component: UserAnalysisDetail,
  },
  {
    path: '/user-stats',
    name: 'Statistiche',
    component: UserStats,
  },
  {
    path: '/user-data/:activeTab',
    name: 'Anagrafica',
    component: UserData,
  },
  {
    path: '/user-data',
    name: 'Anagrafica',
    component: UserData,
  },
  {
    path: '/password-recovery',
    name: 'Recupero Password',
    component: RecoverPassword,
  },
  {
    path: '/wallet',
    name: 'Wallet',
    component: UserWallet,
  },
  {
    path: '/wallet-topup',
    name: 'WalletTopup',
    component: UserWalletTopup,
  },
  {
    path: '/cr-invoice',
    name: 'CR Invoice',
    component: UserCRInvoice,
  },
  {
    path: '/user-invoice-detail/:requestName',
    name: 'Dettaglio Fattura',
    component: UserInvoiceDetail,
  },
  {
    path: '/club-dei-virtuosi',
    name: 'Club dei Virtuosi',
    component: UserCdvSearch,
  },
  {
    path: '/portale-delle-imprese',
    name: 'Portale delle Imprese',
    component: UserClubCompany,
  },
  {
    path: '/crp-profile/:entityType/:entityTax',
    name: 'Profilo CRP',
    component: UserViewProfile,
  },
  {
    path: '/my-profile',
    name: 'La mia azienda',
    component: UserViewProfile,
  },
  {
    path: '/product-list',
    name: 'Visure e Report',
    component: UserProductList,
  },
  {
    path: '/profile-search',
    name: 'Ricerca Profilo',
    component: UserProfileSearch,
  },
  {
    path: '/document-invoice-upload/:requestName',
    name: 'Carica Documenti',
    component: UploadRequest,
  },
  {
    path: '*',
    component: ErrorPage,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
