import { request } from '@/utils/request';

const apiBase = '/crp-invoice-api';

class SendMailService {
    sendMailList = ({ environment, context, language }) => request(`/send-mail/${environment}/${context}/${language}`, false, {
      apiBase,
    });

    getSendMailCompose = ({
      entityId,
      environment,
      context,
      mailCode,
      language,
    }) => request(`/get-mail-request/${entityId}/${language}`, false, {
      apiBase,
      method: 'post',
      data: {
        environment,
        context,
        mailCode,
      },
    });

    sendMailRequest = ({ entityId, language, sendMail }) => request(`/send-mail-request/${entityId}/${language}`, false, {
      apiBase,
      method: 'post',
      data: { ...sendMail },
    });

    sendUpdateMail = ({
      processId,
      message,
      toList,
      ccList,
      bccList,
    }) => request('/mail/send', false, {
      apiBase: '/mailengine',
      method: 'put',
      data: {
        processId,
        message,
        toList,
        ccList,
        bccList,
      },
    });
}

export default new SendMailService();
