import { request } from '@/utils/request';
import { isNotEmpty, isNotExist, isPresent } from '@/utils/validators';
import { decodeAddress } from '@/utils/decodeAddress';

const apiBase = '/backoffice-api';

class SubjectService {
  registerPerson = (user) => {
    const {
      firstName, lastName, email, checkTerms, checkMarketing, mobilePhone, personTaxCode,
    } = user;

    const privacyAccepts = [
      {
        privacyCode: 'accept',
        settingDate: new Date(),
        status: checkTerms,
      },
      {
        privacyCode: 'marketing',
        settingDate: new Date(),
        status: checkMarketing,
      },
    ];

    return request('/create-new-person', false,
      {
        method: 'post',
        data: {
          name: firstName,
          surname: lastName,
          email,
          mobilePhone,
          privacyAccepts,
          personTaxCode,
        },
        apiBase,
      });
  }

  registerCompany = (user) => {
    const {
      companyName, vatNumber, firstName, lastName, email, checkTerms, checkMarketing,
    } = user;

    const privacyAccepts = [
      {
        privacyCode: 'accept',
        settingDate: new Date(),
        status: checkTerms,
      },
      {
        privacyCode: 'marketing',
        settingDate: new Date(),
        status: checkMarketing,
      },
    ];

    return request('/create-new-company', false,
      {
        method: 'post',
        data: {
          name: firstName,
          surname: lastName,
          email,
          companyName,
          vatNumber,
          privacyAccepts,
        },
        apiBase,
      });
  }

  loadSubject = () => request('/subject-detail', false, { apiBase })

  subjectStatus = (companyId) => {
    const url = isPresent(companyId) ? `/subject/status/${companyId}` : '/subject/status';
    return request(url, false, { apiBase });
  }

  subjectImageList = (entityType, entityId) => {
    const url = (isPresent(entityId) && isPresent(entityType)) ? `/subject-image/${entityType}/${entityId}` : '/subject-image/me';
    return request(url, false, { apiBase });
  }

  subjectExtendedStatus = () => request('/extended-status', false, { apiBase })

  subjectSendValidation = (companyId) => {
    const url = isPresent(companyId) ? `/subject/${companyId}/send-validation` : '/subject/send-validation';
    return request(url, false, { method: 'post', apiBase });
  }

  subjectSendTokenRequest = () => request('/subject/send-token', false, { method: 'post', apiBase })

  subjectCheckTokenRequest = (token) => request(`/subject/token/${token}`, true, { method: 'post', apiBase })

  // savePerson = ({ person, companyId }) => request('/person', false, {
  //   method: 'put',
  //   data: {
  //     ...person,
  //     companyId,
  //   },
  //   apiBase,
  // })

  savePerson = ({ person, companyId }) => {
    const {
      anagraficalInfo,
      residencialAddress,
      domicileAddress,
      status,
      ...personData
    } = person;

    const validateResidencial = decodeAddress(residencialAddress);
    if (validateResidencial) validateResidencial.type = 'RESIDENCIAL';

    const validateDomicile = decodeAddress(domicileAddress);
    if (validateDomicile) validateDomicile.type = 'DOMICILE';

    let genderValue = anagraficalInfo.gender;
    if (genderValue !== undefined && genderValue !== null) {
      if (genderValue.value) {
        genderValue = genderValue.value;
      }
    }

    let nationalityCountryCodeValue = anagraficalInfo.nationalityCountryCode;
    if (
      nationalityCountryCodeValue !== undefined
  && nationalityCountryCodeValue !== null
    ) {
      if (nationalityCountryCodeValue.value) {
        nationalityCountryCodeValue = nationalityCountryCodeValue.value;
      }
    }

    const { birthCity } = anagraficalInfo;
    const birthCityValue = birthCity;
    if (birthCity) {
      if (birthCity.countryCode) {
        if (birthCity.countryCode.value) {
          birthCityValue.countryCode = birthCity.countryCode.value;
        }
      }
    }
    let newStatus;
    if (status) {
      newStatus = status.value || status;
    } else {
      newStatus = 'IMPORTED';
    }

    const method = isPresent(person.personId) ? 'put' : 'post';

    return request('/person', false, {
      method,
      data: {
        companyId,
        status: newStatus,
        anagraficalInfo: {
          ...anagraficalInfo,
          gender: genderValue,
          nationalityCountryCode: nationalityCountryCodeValue,
          birthCity: birthCityValue,
        },
        residencialAddress: validateResidencial,
        domicileAddress: validateDomicile,
        ...personData,
      },
      apiBase,
    });
  };

  saveBank = ({ bank, entity }) => {
    const { status } = bank;
    let data;
    if (isNotExist(status)) {
      data = { ...bank, status: 'UNCHECKED' };
    } else {
      data = { ...bank };
    }

    return request(`/bank-account/${entity}`, false, {
      method: 'put',
      data,
      apiBase,
    });
  }

  saveSubject = ({
    person,
    company,
  }) => {
    let data = {};
    if (isNotEmpty(person)) {
      data = {
        ...data,
        person,
      };
    }

    if (isNotEmpty(company)) {
      data = {
        ...data,
        company,
      };
    }

    return request('/subject-detail', false, {
      method: 'put',
      data,
      apiBase,
    });
  };

  saveSubjectPrivacy = ({
    person,
    privacy,
  }) => {
    const data = {
      person,
      privacyAccepts: privacy,
    };

    return request('/subject-privacy', false, {
      method: 'put',
      data,
      apiBase,
    });
  };

  // connectCompany = (company) => {
  //   const {
  //     companyName, vatNumber, relationContext, relationType,
  //   } = company;
  //
  //   return request('/user/create-company', false,
  //     {
  //       method: 'post',
  //       data: {
  //         companyName,
  //         vatNumber,
  //         relationContext,
  //         relationType,
  //       },
  //       apiBase,
  //     });
  // }

  loadCompany = (companyId) => request(`/company/${companyId}`, false, { apiBase });

  loadUserProfiles = (entityType) => request(`/user/profiles/${entityType}`, false, { apiBase });

  loadCompanyRelations = (companyId) => request(`/user/company/${companyId}/connections`, false, { apiBase });

  loadPerson = (personId) => request(`/person/${personId}`, false, { apiBase });

  saveCompany = (company) => request('/company', false, {
    method: 'put',
    data: {
      ...company,
    },
    apiBase,
  })

  personsList = ({
    search, page, size, sort, ...extraFilters
  }) => {
    const requestPage = page || 0;
    const requestSize = size || 10;
    const requestSort = sort || [];
    const requestSearch = search || '';

    return request('/persons', false, {
      method: 'post',
      data: {
        search: requestSearch,
        page: requestPage,
        size: requestSize,
        sort: requestSort,
        ...extraFilters,
      },
      apiBase,
    });
  };

  companiesList = ({
    search, page, size, sort, ...extraFilters
  }) => {
    const requestPage = page || 0;
    const requestSize = size || 10;
    const requestSort = sort || [];
    const requestSearch = search || '';

    return request('/companies', false, {
      method: 'post',
      data: {
        search: requestSearch,
        page: requestPage,
        size: requestSize,
        sort: requestSort,
        ...extraFilters,
      },
      apiBase,
    });
  };

  chechTaxCode = (taxcode) => request(`/check-taxcode/${taxcode}`, true, { apiBase });

  searchHistoryList = ({
    entityType,
    entityId,
    search, page, size, sort, ...extraFilters
  }) => {
    const requestPage = page || 0;
    const requestSize = size || 10;
    const requestSort = sort || [];
    const requestSearch = search || '';

    return request(`/search-history/${entityType}/${entityId}`, false, {
      method: 'post',
      data: {
        search: requestSearch,
        page: requestPage,
        size: requestSize,
        sort: requestSort,
        ...extraFilters,
      },
      apiBase,
    });
  };

  quickSearchCompany = ({
    ownerType,
    ownerId,
    taxCode,
    companyName,
  }) => request('/quick-company-search', false, {
    method: 'post',
    data: {
      ownerType,
      ownerId,
      taxCode,
      companyName,
    },
    apiBase,
  })

  createUserInvitation = ({
    userEmail,
    taxCode,
    relationContext,
    relationType,
    expireDate,
  }) => request('/invitation-person/create', false, {
    method: 'post',
    data: {
      userEmail,
      taxCode,
      relationContext,
      relationType,
      expireDate,
    },
    apiBase,
  })

  acceptInvitation = ({
    relationId,
  }) => request('/invitation-person/accept', false, {
    method: 'put',
    data: {
      relationId,
    },
    apiBase,
  })

  deleteInvitation = ({
    relationId,
  }) => request(`/invitation-person/remove/${relationId}`, false, {
    method: 'delete',
    apiBase,
  })

  countConnections = ({ taxCode }) => request(`/person/roles/${taxCode}`, false, {
    apiBase,
  })
}

export default new SubjectService();
