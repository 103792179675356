import { isNotEmpty, isNotExist, isPresent } from '@/utils/validators';
import {
  toastFailure, toastSuccess, toastSuccessWait, toastUnableToComplete, toastWarning,
} from '@/utils/toast';
// import Vue from 'vue';
import SubjectService from '../services/subject.service';

const personComplete = (person) => person.documents && person.person;
const companyComplete = (company) => company.documents && company.legal && company.legalAddress && company.companyInfo;

const initialState = {
  person: null,
  personIdentityDoc: null,
  company: null,
  companyLegal: null,
  companyLegalIdentityDoc: null,
  companyChamberDoc: null,
  privacyList: null,
  imageList: null,
  personImageList: null,

  status: null,
  extendendStatus: null,
  loading: 0,
  loadingCompany: 0,
  loadingStatus: 0,
  error: {
    person: null,
  },
  userProfiles: [],
  companyRelations: [],
  currentProfile: null,
  executeTaxCode: false,
  history: {
    content: null,
    pageable: null,
  },
  quickSearchResult: [],
  loadingRelation: 0,
};

const subject = {
  namespaced: true,
  state: initialState,
  getters: {
    currentPerson: (state) => state.person,
    currentCompany: (state) => state.company,
    currentCompanyLegal: (state) => state.companyLegal,
    currentUserProfiles: (state) => state.userProfiles || [],
    currentCompanyRelations: (state) => state.companyRelations || [],
    currentPrivacy: (state) => state.privacyList || [],
    currentProfile: (state) => state.currentProfile,
    currentIdentityDoc: (state) => state.personIdentityDoc,
    currentPersonBankDoc: (state) => state.personBankDoc,
    // currentImageList: (state) => state.imageList || [],
    getImage: (state) => (imageType) => {
      const list = state.imageList || [];
      const image = list.filter((item) => item.imageType === imageType);
      if (image.length > 0) {
        return image[0];
      }
      return null;
    },
    getPersonImage: (state) => (imageType) => {
      const list = state.personImageList || [];
      const image = list.filter((item) => item.imageType === imageType);
      if (image.length > 0) {
        return image[0];
      }
      return null;
    },
    currentChamberDoc: (state) => state.companyChamberDoc,
    currentLegalDoc: (state) => state.companyLegalIdentityDoc,
    hasPerson: (state) => isNotEmpty(state.person),
    isPersonalProfile: (state) => isNotExist(state.currentProfile),
    isCheckTaxCode: (state) => state.executeTaxCode,
    isLoading: (state) => state.loading !== 0,
    isLoadingRelation: (state) => state.loadingRelation !== 0,
    isLoadingCompany: (state) => state.loadingCompany !== 0,
    isLoadedData: (state) => isNotEmpty(state.person) || isNotEmpty(state.company),
    isLoadedCompanyData: (state) => {
      const valid = isNotEmpty(state.company);
      if (!valid) return false;
      return isNotEmpty(state.companyLegal);
    },
    currentExtendedStatus: (state) => state.extendendStatus || {},
    canEditPerson: (state) => {
      const status = state.status || {};
      const { notOperative, operative, waitApproval } = (status['person-activity'] || {});
      return !notOperative && !operative && !waitApproval;
    },
    activityPersonStatus: (state) => {
      const status = state.status || {};
      return (status['person-activity'] || {});
    },
    isActiveUser: (state) => {
      const status = state.status || {};
      const { notOperative, operative } = (status['person-activity'] || {});
      return notOperative || operative;
    },
    isAnagraficalComplete: (state) => {
      const status = state.status || {};
      const personOnboard = status['person-onboard'] || {};

      return personComplete(personOnboard);
    },
    isAnagraficalCompanyComplete: (state) => {
      const status = state.status || {};
      const companyOnboard = status.onboard || {};

      // eslint-disable-next-line no-bitwise
      return companyComplete(companyOnboard);
    },
    canSendPersonValidation: (state) => {
      const status = state.status || {};
      const personActivity = status['person-activity'] || {};
      const personOnboard = status['person-onboard'] || {};

      const keys = Object.keys(personActivity);
      if (keys.length === 0) return false;

      const calc = keys.map((key) => (personActivity[key] ? 1 : 0))
        .reduce((result, curr) => result + curr, 0);

      return personComplete(personOnboard) && calc === 0;
    },
    canSendCompanyValidation: (state) => {
      const status = state.status || {};
      const companyActivity = status.activity || {};
      const companyOnboard = status.onboard || {};

      const keys = Object.keys(companyActivity);
      if (keys.length === 0) return false;

      const calc = keys.map((key) => (companyActivity[key] ? 1 : 0))
        .reduce((result, curr) => result + curr, 0);

      return companyComplete(companyOnboard) && calc === 0;
    },
    canEditCompany: (state) => {
      const company = state.company || {};
      return company.status === 'INFO' || company.status === 'LEGAL' || company.status === 'DOCUMENT';
    },
    isCompanyOperative: (state) => {
      const company = state.company || {};
      return company.status === 'OPERATIVE';
    },
    isCompanyNotOperative: (state) => {
      const company = state.company || {};
      return company.status === 'NOT_OPERATIVE';
    },
    isCompanyWait: (state) => {
      const company = state.company || {};
      return company.status === 'VALIDATE';
    },
    activityCompanyStatus: (state) => {
      const status = state.status || {};
      return status.activity || {};
    },
    hasHistory: (state) => isNotEmpty(state.history.pageable),
    historyList: (state) => state.history.content || [],
    historyPageable: (state) => state.history.pageable || {},
    quickSearchResults: (state) => state.quickSearchResult || [],

  },
  actions: {
    logout({ commit }) {
      commit('clearSubjectDetails');
      this.dispatch('score/clearUserScore');
      return Promise.resolve();
    },
    registerPerson({ commit }, userData) {
      commit('setLoading', true);
      return SubjectService.registerPerson(userData).then(
        (response) => {
          commit('loadSubjectSuccess', response.data);
          commit('setLoading', false);
          return Promise.resolve(response.data);
        },
        (error) => {
          commit('registerPersonFailure');
          commit('setLoading', false);
          toastUnableToComplete();
          return Promise.reject(error);
        },
      );
    },
    loadSubject({ commit }) {
      commit('setLoading', true);
      return SubjectService.loadSubject().then(
        (response) => {
          commit('loadSubjectSuccess', response.data);
          const fetchDatas = [
            this.dispatch('subject/subjectStatus'),
            this.dispatch('subject/loadSubjectImage', { entityType: 'person', entityId: response.data.person.personId }),
            this.dispatch('score/getEntityScore', { entityType: 'person', entityId: response.data.person.personId }),
            this.dispatch('wallet/loadWallet', { entityType: 'person', entityId: response.data.person.personId }),
          ];

          return Promise.all(fetchDatas).then(() => {
            commit('setLoading', false);
            return Promise.resolve(response.data);
          },
          (errorAll) => {
            commit('loadSubjectFailure');
            commit('setLoading', false);
            toastUnableToComplete();
            return Promise.reject(errorAll);
          });
        },
        (error) => {
          commit('loadSubjectFailure');
          commit('setLoading', false);
          toastUnableToComplete();
          return Promise.reject(error);
        },
      );
    },
    subjectStatus({ commit }, companyId) {
      commit('setLoadingStatus', true);
      return SubjectService.subjectStatus(companyId).then(
        (response) => {
          commit('subjectStatusSuccess', response.data);
          SubjectService.subjectExtendedStatus().then(
            (response1) => {
              commit('subjectStatusExtendedSuccess', response1.data);
              commit('setLoadingStatus', false);
              return Promise.resolve(response.data);
            },
            (error) => {
              commit('setLoadingStatus', false);
              toastUnableToComplete();
              return Promise.reject(error);
            },
          );
        },
        (error) => {
          commit('setLoadingStatus', false);
          toastUnableToComplete();
          return Promise.reject(error);
        },
      );
    },
    loadSubjectImage({ commit }, { entityType, entityId }) {
      commit('setLoading', true);
      return SubjectService.subjectImageList(entityType, entityId).then(
        (response) => {
          commit('loadSubjectImageSuccess', response.data);
          if (entityType === 'person') {
            commit('loadPersonImageSuccess', response.data);
          }
          commit('setLoading', false);
          return Promise.resolve(response.data);
        },
        (error) => {
          commit('setLoading', false);
          toastUnableToComplete();
          return Promise.reject(error);
        },
      );
    },
    saveSubject({ commit }, { person, company }) {
      commit('setLoading', true);
      return SubjectService.saveSubject({ person, company }).then(
        (response) => {
          commit('loadSubjectSuccess', response.data.data);
          toastSuccess('Salvataggio completato');
          commit('setLoading', false);
          this.dispatch('subject/subjectStatus');
          return Promise.resolve(response);
        },
        (responseError) => {
          if (isNotEmpty(person)) {
            commit('setErrors', {
              section: 'person',
              errors: responseError.errors,
            });
          }
          if (isNotEmpty(company)) {
            commit('setErrors', {
              section: 'company',
              errors: responseError.errors,
            });
          }
          commit('setLoading', false);
          toastFailure('Salvataggio non completato');
          return Promise.reject(responseError);
        },
      );
    },
    saveSubjectPrivacy({ commit }, { person, privacy }) {
      commit('setLoading', true);
      return SubjectService.saveSubjectPrivacy({ person, privacy }).then(
        (response) => {
          commit('loadSubjectSuccess', response.data.data);
          toastSuccess('Salvataggio completato');
          commit('setLoading', false);
          this.dispatch('subject/subjectStatus');
          return Promise.resolve(response);
        },
        (responseError) => {
          if (isNotEmpty(person)) {
            commit('setErrors', {
              section: 'person',
              errors: responseError.errors,
            });
          }
          commit('setLoading', false);
          toastFailure('Salvataggio non completato');
          return Promise.reject(responseError);
        },
      );
    },
    sendValidationRequest({ commit }, companyId) {
      commit('setLoading', true);
      return SubjectService.subjectSendValidation(companyId).then(
        (response) => {
          if (response.status > 399) {
            toastFailure('Invio richiesta non completato');
            commit('setLoading', false);
            return Promise.reject(response);
          }
          commit('loadSubjectSuccess', response.data);
          toastSuccess('Richiesta inviata');
          commit('setLoading', false);
          this.dispatch('subject/subjectStatus', companyId);
          return Promise.resolve(response);
        },
        (responseError) => {
          toastFailure('Invio richiesta non completato');
          commit('setLoading', false);
          return Promise.reject(responseError);
        },
      );
    },
    sendTokenRequest({ commit }) {
      commit('setLoading', true);
      return SubjectService.subjectSendTokenRequest().then(
        (response) => {
          if (response.data) {
            toastSuccess('Richiesta inviata');
          } else {
            toastFailure('Invio richiesta non completato');
          }
          commit('setLoading', false);
          return Promise.resolve(response);
        },
        (responseError) => {
          commit('setLoading', false);
          return Promise.reject(responseError);
        },
      );
    },
    sendCheckTokenRequest({ commit }, token) {
      commit('setLoading', true);
      return SubjectService.subjectCheckTokenRequest(token).then(
        (response) => {
          commit('setLoading', false);
          return Promise.resolve(response.data);
        },
        (responseError) => {
          commit('setLoading', false);
          return Promise.reject(responseError);
        },
      );
    },
    updatePersonDocs({ commit }, { identityDoc, bankDoc }) {
      commit('updatePersonDoc', { personIdentityDoc: identityDoc, personBankDoc: bankDoc });
    },
    setImage({ commit, state }, { imageType, image }) {
      const { imageList } = state;
      let list;
      if (imageList.filter((item) => item.imageType === imageType).length > 0) {
        list = imageList.map((item) => {
          if (item.imageType === imageType) {
            return image;
          }
          return item;
        });
      } else {
        list = [image];
      }
      commit('loadSubjectImageSuccess', list);
    },
    /*    connectCompany({ commit }, {
      companyName, vatNumber, relationContext, relationType, entityType,
    }) {
      commit('setLoading', true);
      return SubjectService.connectCompany({
        companyName, vatNumber, relationContext, relationType,
      }).then(
        (response) => {
          // commit('connectCompanySuccess', response.data.data);
          this.dispatch('subject/loadUserProfiles', entityType);
          toastSuccess('Salvataggio completato');
          commit('setLoading', false);
          return Promise.resolve(response);
        },
        (responseError) => {
          commit('setLoading', false);
          toastFailure('Non è stato possibile collegare l\'azienda');
          return Promise.reject(responseError);
        },
      );
    }, */
    loadUserProfiles({ commit }, entityType) {
      commit('setLoading', true);
      return SubjectService.loadUserProfiles(entityType).then(
        (response) => {
          commit('loadProfilesSuccess', response.data);
          commit('setLoading', false);
          return Promise.resolve(response.data);
        },
        (error) => {
          commit('loadProfilesFailure');
          commit('setLoading', false);
          // toastUnableToComplete();
          return Promise.reject(error);
        },
      );
    },
    loadCompanyRelations({ commit }, companyId) {
      commit('setLoading', true);
      return SubjectService.loadCompanyRelations(companyId).then(
        (response) => {
          commit('loadRelationsSuccess', response.data);
          commit('setLoading', false);
          return Promise.resolve(response.data);
        },
        (error) => {
          commit('loadRelationsFailure');
          commit('setLoading', false);
          toastUnableToComplete();
          return Promise.reject(error);
        },
      );
    },
    loadCompany({ commit }, companyId) {
      commit('setLoadingCompany', true);
      return SubjectService.loadCompany(companyId).then(
        (response) => {
          const fetchDatas = [
            this.dispatch('subject/subjectStatus', companyId),
            this.dispatch('score/getEntityScore', { entityType: 'company', entityId: companyId }),
            this.dispatch('subject/loadSubjectImage', { entityType: 'company', entityId: companyId }),
            this.dispatch('wallet/loadWallet', { entityType: 'company', entityId: companyId }),
          ];

          return Promise.all(fetchDatas).then(() => {
            // commit('setLoading', false);
            commit('loadCompanySuccess', { company: response.data });

            const { legalPersonId } = response.data;
            if (isNotExist(legalPersonId)) {
              commit('setLoadingCompany', false);
              return Promise.resolve(response.data);
            }

            return SubjectService.loadPerson(legalPersonId).then((responseLegal) => {
              commit('loadCompanyLegalSuccess', responseLegal.data);
              commit('setLoadingCompany', false);
              return Promise.resolve(response.data);
            },
            (errorLegal) => {
              commit('loadCompanyLegalFailure');
              commit('setLoadingCompany', false);
              toastUnableToComplete();
              return Promise.reject(errorLegal);
            });
          },
          (errorAll) => {
            commit('loadSubjectFailure');
            commit('setLoading', false);
            toastUnableToComplete();
            return Promise.reject(errorAll);
          });
        },
        (error) => {
          commit('loadCompanyFailure');
          commit('setLoadingCompany', false);
          toastUnableToComplete();
          return Promise.reject(error);
        },
      );
    },
    setCurrentProfile({ commit, dispatch }, profile) {
      if (isNotEmpty(profile)) {
        commit('selectProfile', profile);
        return dispatch('loadCompany', profile.childEntity.entityId);
      }

      commit('loadCompanySuccess', { company: null });
      commit('selectProfile', null);
      return dispatch('loadSubject');
    },
    setCompanyAteco({ commit }, atecoInfo) {
      commit('setAteco', atecoInfo);
    },
    savePerson({ commit }, { person, company }) {
      const { companyId } = company;
      if (isNotExist(companyId)) return Promise.reject();

      commit('setLoading', true);
      return SubjectService.savePerson({ person, companyId }).then(
        (response) => {
          this.dispatch('subject/subjectStatus', companyId);
          commit('savePersonSuccess', { data: response.data.data, companyId });
          toastSuccess('Salvataggio completato');
          commit('setLoading', false);
          return Promise.resolve(response);
        },
        (responseError) => {
          if (isNotEmpty(person)) {
            commit('setErrors', {
              section: 'person',
              errors: responseError.errors,
            });
          }
          if (isNotEmpty(company)) {
            commit('setErrors', {
              section: 'company',
              errors: responseError.errors,
            });
          }
          commit('setLoading', false);
          toastFailure('Salvataggio non completato');
          return Promise.reject(responseError);
        },
      );
    },
    saveCompany({ commit }, company) {
      const { companyId } = company;
      if (isNotExist(companyId)) return Promise.reject();

      commit('setLoading', true);
      return SubjectService.saveCompany(company).then(
        (response) => {
          commit('loadCompanySuccess', { company: response.data.data, keepLegal: true });
          this.dispatch('subject/subjectStatus', companyId);
          toastSuccess('Salvataggio completato');
          commit('setLoading', false);
          return Promise.resolve(response);
        },
        (responseError) => {
          commit('setLoading', false);
          toastFailure('Salvataggio non completato');
          return Promise.reject(responseError);
        },
      );
    },
    checkTaxCode({ commit }, taxcode) {
      if (!isPresent(taxcode)) return Promise.reject();
      commit('setExecuteTaxCode', true);
      return SubjectService.chechTaxCode(taxcode).then(
        (response) => {
          commit('setExecuteTaxCode', false);
          return Promise.resolve(response);
        },
        (responseError) => {
          commit('setExecuteTaxCode', false);
          return Promise.reject(responseError);
        },
      );
    },
    searchHistory({ commit }, {
      search, reset,
    }) {
      commit('setLoading', true);
      if (reset) {
        commit('clearSearchHistory');
      }
      return SubjectService.searchHistoryList(search).then(
        (response) => {
          commit('searchHistorySuccess', { data: response.data });
          commit('setLoading', false);
          return Promise.resolve(response);
        },
        (error) => {
          commit('clearSearchHistory');
          commit('setLoading', false);
          toastUnableToComplete();
          return Promise.reject(error);
        },
      );
    },
    quickCompanySearch({ commit }, {
      ownerType,
      ownerId,
      taxCode,
      companyName,
    }) {
      commit('setLoading', true);
      commit('clearQuickSearchSearch');
      return SubjectService.quickSearchCompany({
        ownerType,
        ownerId,
        taxCode,
        companyName,
      }).then(
        (response) => {
          commit('quickSearchSuccess', { data: response.data });
          commit('setLoading', false);
          this.dispatch('wallet/loadWallet', { entityType: ownerType, entityId: ownerId });
          return Promise.resolve(response);
        },
        (error) => {
          commit('clearQuickSearchSearch');
          commit('setLoading', false);
          toastUnableToComplete();
          return Promise.reject(error);
        },
      );
    },
    createUserInvitation({ commit }, {
      userEmail,
      taxCode,
      relationContext,
      relationType,
      expireDate,
    }) {
      commit('setLoadingRelation', true);
      return SubjectService.createUserInvitation({
        userEmail,
        taxCode,
        relationContext,
        relationType,
        expireDate,
      }).then(
        (response) => {
          commit('setLoadingRelation', false);
          if (response.data?.data) {
            toastSuccess('Richiesta di collaborazione inviata con successo');
          } else {
            toastWarning('Non è stato possibile inviare la richiesta di collaborazione');
          }
          return Promise.resolve(response.data);
        },
        (error) => {
          commit('setLoadingRelation', false);
          toastUnableToComplete();
          return Promise.reject(error);
        },

      );
    },
    acceptInvitation({ commit }, {
      relationId,
      entityType,
    }) {
      commit('setLoadingRelation', true);
      return SubjectService.acceptInvitation({
        relationId,
      }).then(
        (response) => {
          commit('setLoadingRelation', false);
          if (response?.data?.data) {
            this.dispatch('subject/loadUserProfiles', entityType);
          } else {
            toastWarning('Non è stato possibile confermare la richiesta di collaborazione');
          }
          return Promise.resolve(response);
        },
        (error) => {
          commit('setLoadingRelation', false);
          toastUnableToComplete();
          return Promise.reject(error);
        },

      );
    },
    deleteInvitation({ commit }, {
      relationId,
      entityType,
    }) {
      commit('setLoadingRelation', true);
      return SubjectService.deleteInvitation({
        relationId,
      }).then(
        (response) => {
          commit('setLoadingRelation', false);
          if (response?.data?.data) {
            toastSuccess('Richiesta di collaborazione rimossa');
            this.dispatch('subject/loadUserProfiles', entityType);
          } else {
            toastWarning('Non è stato possibile confermare la richiesta di collaborazione');
          }
          return Promise.resolve(response);
        },
        (error) => {
          commit('setLoadingRelation', false);
          toastUnableToComplete();
          return Promise.reject(error);
        },

      );
    },
    processMessagePerson({ commit, dispatch, state }, message) {
      const { person, currentProfile } = state;
      if (!person) return Promise.resolve();
      const { data, entityId } = message;

      if (person.personId !== entityId) return Promise.resolve();

      commit('loadProfilesSuccess', data);

      if (currentProfile !== null) {
        if (data.filter((item) => item.childEntity.entityId === currentProfile.childEntity.entityId).length === 0) {
          dispatch('subject/setCurrentProfile', null);
        }
      }

      toastSuccessWait(`L'elenco delle aziende per ${person.personInfo.name} ${person.personInfo.surname}<br>è stato recuperato`);
      return Promise.resolve();
    },
    countConnections({ commit }, taxCode) {
      commit('setLoadingRelation', true);
      return SubjectService.countConnections({ taxCode })
        .then((response) => {
          commit('setLoadingRelation', false);
          return Promise.resolve(response.data);
        },
        (error) => {
          commit('setLoadingRelation', false);
          return Promise.reject(error);
        });
    },
  },
  mutations: {
    setLoading(state, loading) {
      state.loading += (loading ? 1 : -1);
    },
    setLoadingRelation(state, loading) {
      state.loadingRelation += (loading ? 1 : -1);
    },
    setExecuteTaxCode(state, loading) {
      state.executeTaxCode = loading;
    },
    setLoadingCompany(state, loading) {
      state.loadingCompany += (loading ? 1 : -1);
    },
    setLoadingStatus(state, loading) {
      state.loadingStatus += (loading ? 1 : -1);
    },
    registerPersonFailure(state) {
      console.log('registerPersonFailure');
      state.person = null;
      state.status = null;
    },
    loadSubjectSuccess(state, subjectData) {
      console.log('subject', subjectData);
      state.person = subjectData.person;
      state.personIdentityDoc = subjectData.personIdentityDoc;
      state.personBankDoc = subjectData.personBankDoc;
      state.privacyList = subjectData.privacyAccepts;

      state.status = null;
      state.error = {
        person: null,
      };
    },
    clearSubjectDetails(state) {
      state.person = null;
      state.personIdentityDoc = null;
      state.personBankDoc = null;
      state.privacyList = null;
      state.company = null;
      state.companyLegal = null;
      state.currentProfile = null;
      state.userProfiles = [];
      state.extendendStatus = null;
      state.status = null;
    },
    loadSubjectImageSuccess(state, list) {
      state.imageList = list;
    },
    loadPersonImageSuccess(state, list) {
      state.personImageList = list;
    },
    subjectStatusSuccess(state, status) {
      // console.log('subject', subjectData);
      state.status = status;
    },
    subjectStatusExtendedSuccess(state, status) {
      // console.log('subject', subjectData);
      state.extendendStatus = status;
    },
    updatePersonDoc(state, { personIdentityDoc, personBankDoc }) {
      // console.log('subject', subjectData);
      if (isNotEmpty(personIdentityDoc)) {
        state.personIdentityDoc = personIdentityDoc;
      }
      if (isNotEmpty(personBankDoc)) {
        state.personBankDoc = personBankDoc;
      }
    },
    loadSubjectFailure(state) {
      console.log('loadSubjectFailure');
      state.person = null;
      state.personIdentityDoc = null;
      state.personBankDoc = null;
      state.privacyList = null;
      state.imageList = null;
      state.personImageList = null;

      state.status = null;
    },
    setErrors(state, { section, errors }) {
      console.log('setErrors');
      state.error[section] = errors;
    },
    selectProfile(state, profile) {
      state.currentProfile = profile;
    },
    loadCompanySuccess(state, { company, keepLegal }) {
      state.company = company;
      if (!keepLegal) {
        state.companyLegal = null;
      }
    },
    loadCompanyLegalSuccess(state, companyLegal) {
      state.companyLegal = companyLegal;
    },
    loadCompanyFailure(state) {
      state.company = null;
      state.companyLegal = null;
    },
    loadCompanyLegalFailure(state) {
      state.companyLegal = null;
    },
    loadProfilesSuccess(state, profiles) {
      state.userProfiles = profiles;
    },
    loadProfilesFailure(state) {
      state.userProfiles = [];
    },
    loadRelationsSuccess(state, Relations) {
      state.companyRelations = Relations;
    },
    loadRelationsFailure(state) {
      state.companyRelations = [];
    },
    setAteco(state, atecoInfo) {
      const { company } = state;
      if (isNotEmpty(company)) {
        if (isNotEmpty(atecoInfo)) {
          company.atecoInfo = atecoInfo;
          const { classify, category, subcategory } = atecoInfo;
          company.attribute.atecoCode = subcategory || category || classify;
        } else {
          delete company.atecoInfo;
          company.attribute.atecoCode = null;
        }
        state.company = { ...company };
      }
    },
    savePersonSuccess(state, { data, companyId }) {
      const { company } = state;
      if (company.companyId === companyId) {
        state.companyLegal = data;
        state.company = { ...company, legalPersonId: data.personId };
      }
    },
    clearQuickSearchSearch(state) {
      state.quickSearchResult = [];
    },
    quickSearchSuccess(state, { data }) {
      state.quickSearchResult = data.data;
    },
    clearSearchHistory(state) {
      state.history.content = null;
      state.history.pageable = null;
    },
    searchHistorySuccess(state, { data }) {
      const { content, ...pageable } = data;
      state.history.content = content;
      state.history.pageable = pageable;
    },
  },
};

export default subject;
