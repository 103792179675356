<template>
  <user-structure
    hide-button
    hide-switch
    :title="headingText"
    :title-button="{
      'content': 'Le mie richieste',
      'route': 'user-analysis-history',
      'visibility': isOperative,
    }"
  >
    <template #body>
      <div>
        <b-card class="border-0 mb-3">
          <h3>Per le Aziende</h3>
          <product-table
            :list="products"
            :requestType="requestType"
          />
        </b-card>
        <b-card class="border-0">
          <h3>Per le Persone Fisiche</h3>
          <product-table
            :list="productsPerson"
            :requestType="requestTypePerson"
          />
        </b-card>
      </div>
    </template>
  </user-structure>
</template>

<script>
import { isNotEmpty } from '@/utils/validators';

const UserStructure = () => import('@/components/layout/UserStructure.vue');
const ProductTable = () => import('@/components/productTable/ProductTable.vue');

export default {
  name: 'UserProductList',
  components: {
    UserStructure,
    ProductTable,
  },
  data() {
    return {
      hasLoaded: false,
      requestType: 'pr_company',
      requestTypePerson: 'pr_person',
    };
  },
  computed: {
    hasScoreData() {
      if (this.hasLoaded !== this.$store.getters['score/hasCurrentScore']) {
        this.changePreloaderStatus(this.$store.getters['score/hasCurrentScore']);
      }
      return this.$store.getters['score/hasCurrentScore'];
    },
    headingText() {
      return 'Visure e Report';
    },
    shoutBoxData() {
      return {
        title: this.headingText,
      };
    },
    products() {
      const type = isNotEmpty(this.requestType) ? this.requestType : 'pr_company';
      console.log('this.productDetail.requestType', type, this.$store.getters['tableUtils/getTableList'](type));
      return this.$store.getters['tableUtils/getTableList'](type) || [];
    },
    productsPerson() {
      const type = isNotEmpty(this.requestTypePerson) ? this.requestTypePerson : 'pr_person';
      console.log('this.productDetail.requestType person', type, this.$store.getters['tableUtils/getTableList'](type));
      return this.$store.getters['tableUtils/getTableList'](type) || [];
    },
    isCompanyProfile() {
      const currentProfile = this.$store.getters['subject/currentCompany'];
      return isNotEmpty(currentProfile);
    },
    activityStatus() {
      if (this.isCompanyProfile) {
        return this.$store.getters['subject/activityCompanyStatus'];
      }
      return this.$store.getters['subject/activityPersonStatus'];
    },
    isOperative() {
      return this.activityStatus.operative;
    },
  },
  metaInfo() {
    return {
      title: 'Area utente - CR Pagamenti',
      meta: [
        { property: 'og:title', content: 'Area utente - CR Pagamenti' },
        { property: 'og:site_name', content: 'CR Pagamenti' },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'index,follow' },
      ],
    };
  },
  methods: {
    changePreloaderStatus(state) {
      this.hasLoaded = state;
      console.debug('UserProductList setpreloader', !state);
      this.$store.dispatch('setPreloader', !state);
    },
    routeTo(path) {
      this.$router.replace(path);
    },
  },
};
</script>

<style scoped>

</style>
