<template>
  <main class="main--not-logged">
    <div class="banner">
      <img
        :src="require('../assets/img/architecture-g19c3e4465_1920_low.jpg')"
        class="banner__img"
      />
    </div>
    <div class="main-content">
      <shout-box
        :data="shoutBoxData"
      >
        <template slot="custom-content">
          <div class="shoutbox__custom-content">
            <b-card class="col-10 offset-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 p-0">
              <div v-if="isCheck">
                Controllo del token in corso
              </div>
              <div v-if="!isCheck" class="text-center py-5">
                <div v-if="validatedToken">
                  La tua email è stata verificata. Effettua la login per accedere.
                </div>
                <div v-if="!validatedToken">
                  Il codice ricevuto non è più valido.<br /><br />Effettua la login per accedere e richiedere un nuovo codice
                </div>
                <div class="d-flex justify-content-center mt-4">
                  <router-link to="/accedi" class="btn btn-secondary btn-lg">
                    Accedi
                  </router-link>
                </div>
              </div>
            </b-card>
          </div>
        </template>
      </shout-box>
    </div>
    <footer-component />
  </main>
</template>

<script>
import { extractErrorMessage } from '@/utils/validators';

const ShoutBox = () => import('@/components/ShoutBox.vue');
const FooterComponent = () => import('@/components/layout/FooterComponent.vue');

export default {
  name: 'ValidateToken',
  components: { ShoutBox, FooterComponent },
  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    console.log('token', this.$route.params.token);
    // forzare un logout
    this.$store.dispatch('auth/resetToken');
    this.checkToken();
  },
  data() {
    return {
      submitted: false,
      validatedToken: false,
      shoutBoxData: {
        title: 'Accedi o <span class="text-warning">registrati</span> per utilizzare tutti i servizi di CR Pagamenti',
        subtitle: 'Certifica la tua affidabilità nella gestione dei rapporti commerciali'
          + ' e nella gestione dei pagamenti, migliora il tuo scoring chiedendo i feedback'
          + ' ai tuoi fornitori',
      },
    };
  },
  computed: {
    isCheck() {
      return this.$store.getters['subject/isLoading'];
    },
  },
  metaInfo() {
    return {
      title: 'Check Email - CR Pagamenti',
      meta: [
        { property: 'og:title', content: 'Check Email - CR Pagamenti' },
        { property: 'og:site_name', content: 'CR Pagamenti' },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'noindex, nofollow' },
      ],
    };
  },
  methods: {
    checkToken() {
      this.submitted = true;
      this.$store.dispatch('subject/sendCheckTokenRequest', this.$route.params.token)
        .then(
          (data) => {
            this.validatedToken = data;
            this.submitted = false;
          },
          (error) => {
            this.message = extractErrorMessage(error);
            this.submitted = false;
          },
        );
    },
  },
  mounted() {
    console.debug('ValidateToken setpreloader', false);
    this.$store.dispatch('setPreloader', false);
  },
};
</script>

<style scoped>

</style>
