import { request } from '@/utils/request';
import { isPresent } from '@/utils/validators';

const apiBase = '/crp-api';

class ScoreService {
  loadScoreDetail = ({
    entityType,
    entityId,
  }) => request(`/user/score/${entityType}/${entityId}`, false, { method: 'get', apiBase });

  createFeedbackRequest = ({
    entityType, entityId, feedbackType, feedbackQuestion, singleResponse, note,
  }) => {
    const data = {
      entityId,
      feedbackType,
      feedbackQuestion,
      singleResponse,
      note,
    };

    return request(`/user/feedback/create-request/${entityType}`, false, {
      method: 'post',
      data,
      apiBase,
    });
  }

  fetchFeedbackStatistics = ({
    entityType, entityId, interval, type,
  }) => {
    let url = `/user/feedback/statistics/${entityType}/${interval}`;
    if (isPresent(entityId)) {
      url = `${url}/${entityId}`;
    }
    if (isPresent(type)) {
      url = `${url}?type=${type}`;
    }
    return request(url, false, { method: 'get', apiBase });
  }

  fetchFeedbackHistoryStatistics = ({
    entityType, entityId, interval, type,
  }) => {
    let url = `/user/feedback/history/${entityType}/${interval}`;
    if (isPresent(entityId)) {
      url = `${url}/${entityId}`;
    }
    if (isPresent(type)) {
      url = `${url}?type=${type}`;
    }
    return request(url, false, { method: 'get', apiBase });
  }

  searchOwnFeedback = (entityType, entityId, search) => {
    let url = `/user/feedbacks/${entityType}`;
    if (isPresent(entityId)) {
      url = `${url}/${entityId}`;
    }

    return request(url, false,
      {
        method: 'post',
        data: {
          ...search,
        },
        apiBase,
      });
  }

  closeFeedback = (entityType, feedbackName) => request(`/user/feedback/${entityType}/${feedbackName}/close`, false,
    {
      method: 'post',
      apiBase,
    });

  createUserReport = (createReport) => request('/user/report/create', false,
    {
      method: 'post',
      data: {
        ...createReport,
      },
      apiBase,
    });

  searchOwnUserReports = (entityType, entityId, search) => request(`/user-reports/${entityType}/${entityId}`, false,
    {
      method: 'post',
      data: {
        ...search,
      },
      apiBase,
    });

  loadUserReport = (reportName) => request(`/user-report/${reportName}`, false, { method: 'get', apiBase });

  updateStatusReport = ({
    reportName, ...data
  }) => request(`/user-report/${reportName}/status`, false,
    {
      method: 'put',
      data,
      apiBase,
    });

  litigationStatusReport = ({
    reportName, ...data
  }) => request(`/user-report/${reportName}/response`, false,
    {
      method: 'put',
      data,
      apiBase,
    });

  fetchReportHistoryStatistics = ({
    entityType, entityId, interval, type,
  }) => {
    let url = `/user/user-report/history/${entityType}/${interval}`;
    if (isPresent(entityId)) {
      url = `${url}/${entityId}`;
    }
    if (isPresent(type)) {
      url = `${url}?type=${type}`;
    }
    return request(url, false, { method: 'get', apiBase });
  }

  updateUserNote = ({
    reportName, ...data
  }) => request(`/user/user-report/${reportName}/update-note`, false,
    {
      method: 'put',
      data,
      apiBase,
    });

  searchClub = ({
    entityType,
    taxCode,
  }) => request(`/club/search/${entityType}/${taxCode}`, false, { method: 'get', apiBase });

  detailClub = ({
    entityType,
    taxCode,
  }) => request(`/club/detail/${entityType}/${taxCode}`, false, { method: 'get', apiBase });
}

export default new ScoreService();
