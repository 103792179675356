<template>
  <div class="mt-3">
    <user-structure is-dashboard>
      <template #banner>
        <div class="banner">
          <img
            :src="require('../assets/img/bg-crp_low.jpg')"
            class="banner__img"
          />
          <div class="banner__content__wrapper" v-if="extendedStatus.userStatus !== 'wait'">
            <div class="banner__content banner__content--badge">
              <div class="banner__content__img">
                <router-link
                  to="/user-stats"
                >
                  <b-img fluid :src="badgeUri" :class="qrCodeClasses" />
                </router-link>
              </div>
              <div class="banner__content__data">
                <div class="banner__content__data__top">
                  <p>
                    {{currentName}}
                  </p>
                </div>
                <div class="banner__content__data__bottom">
                  <div class="d-flex align-items-center">
                    <h5 class="mb-0">
                      {{totalPoint}}
                    </h5>
                    <div
                      class="banner__content__data__bottom__cockade"
                      v-b-tooltip.hover
                      title="Club dei Virtuosi"
                      v-if="isClubActive"
                    >
                      <font-awesome-icon icon="certificate"/>
                      <font-awesome-icon icon="check"/>
                    </div>
                  </div>
                  <div v-if="isOperative">
                    <b-button
                      variant="info"
                      size="sm"
                      @click="openModalFeedback"
                    >
                      Richiedi feedback
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="banner__content banner__content--wallet"
              v-if="isOperative"
            >
              <div class="banner__content__img">
                <font-awesome-icon
                  icon="wallet"
                  class="fa-fw"
                />
              </div>
              <div class="banner__content__data">
                <div class="banner__content__data__top">
                  <p>Credito disponibile</p>
                </div>
                <div class="banner__content__data__bottom">
                  <div class="d-flex align-items-center">
                    <h5 class="mb-0">
                      {{walletValue}}
                    </h5>
                  </div>
                  <div>
                    <b-button
                      variant="info"
                      size="sm"
                      @click="routeTo('/wallet')"
                    >
                      Wallet
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #body>
        <div>
          <b-row v-if="isActiveUser && isOperative">
            <b-col :md="topRowMdColWidth" :xl="topRowLgColWidth" class="dashcard__wrapper">
              <div class="dashcard">
                <div class="dashcard__header">
                  <div class="dashcard__icon">
                    <inline-svg
                      :src="require('../assets/img/market-analysis.svg')"
                      fill="#0a2538"
                      color="#0a2538"
                      height="30px"
                      viewBox="0 0 100 100"
                      width="30px"
                    ></inline-svg>
                  </div>
                  <span class="dashcard__title">
                    Download visure & report
                  </span>
                </div>
                <div class="dashcard__body">
                  <p>
                    Scarica una vasta gamma di documenti camerali e rapporti riguardanti Aziende
                    Italiane o richiedi uno dei nostri servizi di reportistica.
                    Consulta il nostro listino aggiornato.
                  </p>
                </div>
                <div class="dashcard__buttons">
                  <div>
                    <b-button
                      variant="info"
                      @click="routeTo('/product-list')"
                    >
                      Accedi al listino
                    </b-button>
                  </div>
                </div>
              </div>
            </b-col>
            <b-col :md="topRowMdColWidth" :xl="topRowLgColWidth" class="dashcard__wrapper">
              <div class="dashcard">
                <div class="dashcard__header">
                  <div class="dashcard__icon">
                    <inline-svg
                      :src="require('../assets/img/review.svg')"
                      fill="#0a2538"
                      color="#6C757D"
                      height="30px"
                      viewBox="0 0 100 100"
                      width="30px"
                    ></inline-svg>
                  </div>
                  <span class="dashcard__title">
                    Scoring CRP
                  </span>
                </div>
                <div class="dashcard__body">
                  <p>
                    {{ crpServicesText }}
                  </p>
                </div>
                <div class="dashcard__buttons">
                  <div>
                    <b-button
                      variant="info"
                      @click="routeTo('profile-search')"
                    >
                      Ricerca aziende
                    </b-button>
                  </div>
                </div>
              </div>
            </b-col>
            <b-col :md="topRowMdColWidth" :xl="topRowLgColWidth" class="dashcard__wrapper">
              <div class="dashcard">
                <div class="dashcard__header">
                  <div class="dashcard__icon">
                    <inline-svg
                      :src="require('../assets/img/review_2.svg')"
                      fill="#0a2538"
                      color="#6C757D"
                      height="30px"
                      viewBox="0 0 100 100"
                      width="30px"
                    ></inline-svg>
                  </div>
                  <span class="dashcard__title">
                    Effettua una segnalazione
                  </span>
                </div>
                <div class="dashcard__body">
                  <p>
                    Se hai rapporti con persone o imprese e vuoi segnalarne la correttezza/scorrettezza
                    contrattuale o professionale, crea una segnalazione.
                  </p>
                </div>
                <div
                  v-if="isOperative"
                  class="dashcard__buttons"
                >
                  <b-button
                    variant="info"
                    @click="routeTo('/create-report')"
                  >
                    Invia una segnalazione
                  </b-button>
                </div>
              </div>
            </b-col>
            <b-col md="6" xl="3" class="dashcard__wrapper" v-if="isCompanyProfile && isOperative">
              <div class="dashcard">
                <div class="dashcard__header">
                  <div class="dashcard__icon">
                    <inline-svg
                      :src="require('../assets/img/review_2.svg')"
                      fill="#0a2538"
                      color="#6C757D"
                      height="30px"
                      viewBox="0 0 100 100"
                      width="30px"
                    ></inline-svg>
                  </div>
                  <span class="dashcard__title">
                    CR INVOICE
                  </span>
                </div>
                <div class="dashcard__body">
                  <p>
                    Se hai rapporti con persone o imprese e vuoi segnalarne la correttezza/scorrettezza
                    contrattuale o professionale, crea una segnalazione.
                  </p>
                </div>
                <div class="dashcard__buttons">
                  <b-button
                    variant="info"
                    @click="routeTo('/cr-invoice')"
                  >
                    CR INVOICE
                  </b-button>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" class="dashcard__wrapper mt-3" v-if="isOperative">
              <div class="dashcard">
                <div class="dashcard__header">
                  <div class="dashcard__icon">
                    <inline-svg
                      :src="require('../assets/img/financial.svg')"
                      fill="#0a2538"
                      color="#6C757D"
                      height="30px"
                      viewBox="0 0 100 100"
                      width="30px"
                    ></inline-svg>
                  </div>
                  <span class="dashcard__title">
                    Ultime richieste
                  </span>
                </div>
                <div class="dashcard__body">
                  <user-analysis-history-table
                    :complete-data="hasScoreData"
                    :data-size="3"
                    minimal
                    :update-key="currentName.toString()"
                  />
                </div>
              </div>
            </b-col>
            <b-col lg="8" offset-lg="2" class="dashcard__wrapper" v-if="!isOperative && !isWaitApproval">
              <div class="dashcard">
                <div class="dashcard__header">
                  <div class="dashcard__icon">
                    <inline-svg
                      :src="require('../assets/img/financial.svg')"
                      fill="#0a2538"
                      color="#6C757D"
                      height="30px"
                      viewBox="0 0 100 100"
                      width="30px"
                    ></inline-svg>
                  </div>
                  <span class="dashcard__title">
                  SERVIZI CRP
                </span>
                </div>
                <div class="dashcard__body">
                  <p>
                    COMPLETA IL TUO PROFILO PER ACCEDERE A TUTTI I SERVIZI DISPONIBILI
                  </p>
                </div>
                <div class="dashcard__buttons">
                  <b-button
                    size="lg"
                    variant="warning"
                    block
                    @click="routeTo('/user-data')"
                  >
                    AGGIORNA PROFILO
                  </b-button>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </template>
    </user-structure>
    <modal-feedback id="userFeedback" />
<!--    <modal-add-company id="addCompany" />-->
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';
import { debounce } from 'lodash';
import { Settings } from 'luxon';
import {
  checkTaxCode, extractErrorMessage, isNotEmpty, isPresent,
} from '@/utils/validators';
import { searchCompany } from '../utils/selectSearch';
import { toastInformation } from '../utils/toast';
import { createBadgeUri } from '../utils/utilities';
import { formatCurrency, formatDecimal, normalizeDisplayName } from '../utils/formatterHelper';

const ModalFeedback = () => import('@/components/ModalFeedback.vue');
const UserStructure = () => import('@/components/layout/UserStructure.vue');
const UserAnalysisHistoryTable = () => import('@/components/userAnalysis/UserAnalysisHistoryTable.vue');

export default {
  name: 'UserDashboard',
  components: {
    UserStructure,
    InlineSvg,
    ModalFeedback,
    UserAnalysisHistoryTable,
  },
  data() {
    return {
      hasLoaded: null,
      hasSearchResults: false,
      userChosenType: 'person',
      currentSelection: '',
      entityType: 'PERSON',
      entityId: '',
      options: [],
      personName: '',
      companyName: '',
      dataTax: '',
      completed: false,
      hasError: false,
      hasWrongTaxCode: false,
    };
  },
  computed: {
    isCompanyProfile() {
      const currentProfile = this.$store.getters['subject/currentCompany'];
      return isNotEmpty(currentProfile);
    },
    hasScoreData() {
      // if (this.hasLoaded !== this.$store.getters['score/hasCurrentScore']) {
      //   this.changePreloaderStatus(this.$store.getters['score/hasCurrentScore']);
      // }
      return this.$store.getters['score/hasCurrentScore'];
    },
    serialCode() {
      if (this.hasScoreData) {
        const score = this.$store.getters['score/getCurrentScore'];
        return score.serialCode;
      }
      return '';
    },
    entityEmail() {
      if (this.isCompanyProfile) {
        return this.$store.getters['subject/currentCompany'].attribute.mainPecEmail.contactData;
      }
      return this.$store.getters['subject/currentPerson']?.personInfo.email;
    },
    currentName() {
      if (this.isCompanyProfile) {
        return this.$store.getters['subject/currentCompany'].info.companyName;
      }
      return normalizeDisplayName(this.$store.getters['auth/currentName']);
    },
    userName() {
      return normalizeDisplayName(this.$store.getters['auth/currentName']);
    },
    userEmail() {
      return this.$store.getters['auth/currentEmail'];
    },
    activityStatus() {
      if (this.isCompanyProfile) {
        // console.log('logging company', this.$store.getters['subject/activityCompanyStatus']);
        return this.$store.getters['subject/activityCompanyStatus'];
      }
      return this.$store.getters['subject/activityPersonStatus'];
    },
    isOperative() {
      return this.activityStatus.operative;
    },
    isWaitApproval() {
      return this.activityStatus.waitApproval;
    },
    totalPoint() {
      if (this.activityStatus.operative === false) return 'N.D.';
      if (this.hasScoreData) {
        const score = this.$store.getters['score/getCurrentScore'];
        return formatDecimal(score.totalPoint);
      }
      return 0;
    },
    isClubActive() {
      if (this.hasScoreData) {
        const score = this.$store.getters['score/getCurrentScore'];
        return score.clubActive;
      }
      return false;
    },
    receivedClasses() {
      if ((this.countTotal / this.countMax) * 100 < 10) {
        return 'progress--lg progress--value-xs';
      }
      return 'progress--lg';
    },
    countTotal() {
      if (this.hasScoreData) {
        const score = this.$store.getters['score/getCurrentScore'];
        return score.globalVotes?.countTotal;
      }
      return 0;
    },
    countMax() {
      let increm = this.countTotal * 0.2;
      if (increm < 1) {
        increm = 2;
      }
      return this.countTotal + increm;
    },
    viewState() {
      return this.$store.getters.getDashboardState;
    },
    extendedStatus() {
      return this.$store.getters['subject/currentExtendedStatus'];
    },
    isActiveUser() {
      return this.$store.getters['subject/isActiveUser'];
    },
    dashcardBodyEvalClasses() {
      if (this.isOperative) return 'dashcard__body my-auto';
      return 'dashcard__body';
    },
    isCompany() {
      return this.userChosenType === 'company';
    },
    isPerson() {
      return this.userChosenType === 'person';
    },
    baseButtonClasses() {
      return 'btn-wizard--large';
    },
    requestClassesCompany() {
      if (this.isCompany) return `${this.baseButtonClasses} active`;
      return this.baseButtonClasses;
    },
    requestClassesPerson() {
      if (this.isPerson) return `${this.baseButtonClasses} active`;
      return this.baseButtonClasses;
    },
    searchButtonWrapperClasses() {
      const a = 'me-2';
      if (this.currentSelection === '') return `${a} is-disabled`;
      return a;
    },
    searchButtonClasses() {
      if (this.currentSelection === '') return 'disabled';
      return '';
    },
    formPersonClasses() {
      const a = 'form-control-lg';
      if (this.hasError) return `${a} is-invalid`;
      return 'form-control-lg';
    },
    qrCodeClasses() {
      if (this.activityStatus.operative === false) return 'has-filter--blur';
      return '';
    },
    badgeUri() {
      // return createBadgeUri(this.serialCode);
      if (isPresent(this.serialCode)) {
        return createBadgeUri(this.serialCode);
      }
      return '';
    },
    walletValue() {
      if (isNotEmpty(this.$store.getters['wallet/getWallet']?.amount)) {
        const a = this.$store.getters['wallet/getWallet']?.amount.amount;
        const b = this.$store.getters['wallet/getWallet']?.amount.currency;
        return formatCurrency(a, 'it', b);
      }
      return 'N.D.';
    },
    topRowMdColWidth() {
      return this.isCompanyProfile ? '6' : '4';
    },
    topRowLgColWidth() {
      return this.isCompanyProfile ? '3' : '4';
    },
    crpServicesText() {
      if (this.isCompanyProfile) {
        return 'Scopri se un\'azienda risulta affidabile e controllane i '
        + 'dati tramite il nostro sistema di scoring ed i nostri report completi, oppure richiedi '
        + 'un business plan';
      }
      return 'Scopri se un\'azienda risulta affidabile e controllane i dati tramite il nostro '
        + 'sistema di scoring ed i nostri report completi.';
    },
  },
  metaInfo() {
    return {
      title: 'Area utente - CR Pagamenti',
      meta: [
        { property: 'og:title', content: 'Area utente - CR Pagamenti' },
        { property: 'og:site_name', content: 'CR Pagamenti' },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'noindex,nofollow' },
      ],
    };
  },
  // beforeCreate() {
  //   console.log('preloader dash beforeCreate', this.$store.getters.isPreloading);
  //   this.$store.dispatch('setPreloader', true);
  // },
  // created() {
  //   console.log('preloader dash created', this.$store.getters.isPreloading);
  //   this.$store.dispatch('setPreloader', true);
  // },
  beforeMount() {
    console.debug('UserDashboard beforemount setpreloader', true);
    this.$store.dispatch('setPreloader', true);
  },
  mounted() {
    this.fetchDecodes();
    // console.log('preloader dash cehck', this.$store.getters.isPreloading);
    // this.$nextTick(() => {
    //   // Code that will run only after the
    //   // entire view has been rendered
    //   console.log('preloader dash cehck tick', this.$store.getters.isPreloading);
    //   if (this.$store.getters.isPreloading) {
    //     console.log('preloader dash cehck tick if', this.$store.getters.isPreloading);
    //     this.$store.dispatch('setPreloader', false);
    //   }
    //   console.log('preloader dash cehck tick post', this.$store.getters.isPreloading);
    // });
    // this.$store.dispatch('setPreloader', true);
    // console.log('subject check', this.$store.getters['subject/isLoading']);
    if (!this.$store.getters['subject/isLoading']) {
      // console.log('subject load check', this.$store.getters['subject/isLoading']);
      console.debug('UserDachboard mounted setpreloader', false);
      this.$store.dispatch('setPreloader', false);
    }
  },
  // beforeUpdate() {
  //   console.log('preloader dash beforeUpdate', this.$store.getters.isPreloading);
  //   this.$store.dispatch('setPreloader', true);
  // },
  // updated() {
  //   console.log('preloader dash updated', this.$store.getters.isPreloading);
  // },
  methods: {
    fetchDecodes() {
      if (!this.$store.getters['tableUtils/hasNationList']) {
        this.$store.dispatch('tableUtils/loadNationList').then(() => {
          console.log('load nations');
        },
        () => {
          console.log('nations failed', this.$route.name);
        });
      }

      if (!this.$store.getters['tableUtils/hasAbiList']) {
        this.$store.dispatch('tableUtils/loadAbiList').then(() => {
          console.log('load abi');
        },
        () => {
          console.log('abi failed', this.$route.name);
        });
      }

      if (!this.$store.getters['tableUtils/hasMimeIcons']) {
        this.$store.dispatch('tableUtils/loadMimeIcons').then(() => {
          console.log('load mime');
        },
        () => {
          console.log('mime failed', this.$route.name);
        });
      }

      if (!this.$store.getters['documents/hasDocumentTypes']) {
        this.$store.dispatch('documents/loadDocTypes', Settings.defaultLocale).then(() => {
          console.log('load doc type');
        },
        () => {
          console.log('doc types failed', this.$route.name);
        });
      }
    },
    openModalFeedback() {
      this.$bvModal.show('userFeedback');
    },
    // addCompanyModal() {
    //   this.$bvModal.show('addCompany');
    // },
    // changePreloaderStatus(state) {
    //   this.hasLoaded = state;
    //   this.$store.dispatch('setPreloader', !state);
    // },
    setDashboardState(state) {
      this.$store.dispatch('setDashboard', state);
    },
    reload() {
      console.log('qrbadge feedback reload');
      if (this.reloadData) this.reloadData();
    },
    sendTokenRequest() {
      console.log('Send validation request');
      this.$confirm(
        {
          message: 'Vuoi richiedere la verifica della tua email?',
          button: {
            no: 'No',
            yes: 'Si',
          },
          /**
           * Callback Function
           * @param {Boolean} confirm
           */
          callback: (confirm) => {
            if (confirm) {
              this.$store.dispatch('subject/sendTokenRequest').then(() => {
                this.submitted = false;
              },
              (error) => {
                this.message = extractErrorMessage(error);
                this.submitted = false;
              });
            }
          },
        },
      );
    },
    routeTo(path) {
      this.$router.push(path);
    },
    doQuickSearch() {
      if (this.isPerson) {
        const reporterType = 'PERSON';

        const reportRequest = {
          entityType: reporterType,
          taxCode: this.currentSelection,
        };

        this.completed = false;
        this.submitted = true;
        this.$store.dispatch('score/searchClub', reportRequest)
          .then((clubSubject) => {
            this.clubSubject = clubSubject.data;
            this.completed = true;
            this.submitted = false;
            this.hasSearchResults = true;
            this.personName = `${this.clubSubject.name} ${this.clubSubject.surname}`;
            this.dataTax = this.clubSubject.taxCode;
            this.entityType = this.clubSubject.entityType;
            this.entityId = this.clubSubject.entityId;
          },
          (error) => {
            console.error('Errore in ricerca club virtuosi', error);
            this.completed = false;
            this.submitted = false;
            this.hasError = true;
          });
      }
      if (this.isCompany) {
        const reporterType = 'COMPANY';

        const reportRequest = {
          entityType: reporterType,
          taxCode: this.currentSelection.company.info.vatNumber,
        };

        this.completed = false;
        this.$store.dispatch('score/searchClub', reportRequest)
          .then((clubSubject) => {
            this.clubSubject = clubSubject.data;
            this.completed = true;
            this.hasSearchResults = true;
            console.log('this.reportCreated', this.clubSubject);
            this.companyName = this.clubSubject.name;
            this.dataTax = this.clubSubject.taxCode;
            this.entityType = this.clubSubject.entityType;
          },
          (error) => {
            console.error('Errore in ricerca club virtuosi', error);
            this.completed = false;
          });
      }
    },
    resetSearch() {
      this.currentSelection = '';
      this.completed = false;
      this.hasSearchResults = false;
      this.hasError = false;
      this.hasWrongTaxCode = false;
    },
    setRequestType(val) {
      this.userChosenType = val;
      this.resetSearch();
    },
    currentTaxCodeChange(e) {
      console.log('currentTaxCodeChange');
      this.currentSelection = e.target.value;
      this.hasWrongTaxCode = !checkTaxCode(this.currentSelection);
      this.completed = false;
      this.hasSearchResults = false;
    },
    checkTaxCodeChange(e) {
      console.log('checkTaxCodeChange', e);
      this.hasWrongTaxCode = !checkTaxCode(this.currentSelection);
    },
    onSearch(search, loading) {
      console.log('searching?');
      if (search.length) {
        console.log('should be searching');
        loading(true);
        this.search(loading, search, this);
      }
    },
    search: debounce((loading, search, vm) => {
      console.log('inside search');
      let request = null;
      request = searchCompany(search);
      if (!request) {
        // eslint-disable-next-line no-param-reassign
        vm.options = [];
        return;
      }
      request.then((res) => {
        console.log('res', res);
        // eslint-disable-next-line no-param-reassign
        vm.options = res;
        loading(false);
      }).catch((err) => {
        console.log('error search ', vm.userChosenType, err);
        loading(false);
      });
    }, 350),
    onRequestReport() {
      // console.log('report popup');
      toastInformation('Invio richiesta report');
    },
    onComplete() {
      this.$router.replace(`/crp-profile/${this.entityType}/${this.dataTax}`);
    },
    onRegisterNew() {
      toastInformation('Invio registra nuovo');
    },
  },
};
</script>
