<template>
  <user-structure
    hide-button
    hide-switch
    hasCreateAnalysis
    :title="headingText"
    :title-button="{
      'content': 'Richiedi un servizio',
      'route': 'product-list',
      'visibility': isOperative,
    }"
  >
    <template #body>
      <user-analysis-history-table
        :complete-data="hasScoreData"
        standalone
        :update-key="moment().format()"
      />
    </template>
  </user-structure>
</template>

<script>
import moment from 'moment';
import { isNotEmpty } from '../utils/validators';

const UserStructure = () => import('@/components/layout/UserStructure.vue');
const UserAnalysisHistoryTable = () => import('@/components/userAnalysis/UserAnalysisHistoryTable.vue');

export default {
  name: 'UserAnalysisHistory',
  components: {
    UserAnalysisHistoryTable,
    UserStructure,
  },
  data() {
    return {
      hasLoaded: false,
    };
  },
  computed: {
    moment() {
      return moment;
    },
    hasScoreData() {
      if (this.hasLoaded !== this.$store.getters['score/hasCurrentScore']) {
        this.changePreloaderStatus(this.$store.getters['score/hasCurrentScore']);
      }
      return this.$store.getters['score/hasCurrentScore'];
    },
    headingText() {
      return 'Elenco Richieste';
    },
    shoutBoxData() {
      return {
        title: this.headingText,
      };
    },
    isCompanyProfile() {
      const currentProfile = this.$store.getters['subject/currentCompany'];
      return isNotEmpty(currentProfile);
    },
    activityStatus() {
      if (this.isCompanyProfile) {
        return this.$store.getters['subject/activityCompanyStatus'];
      }
      return this.$store.getters['subject/activityPersonStatus'];
    },
    isOperative() {
      return this.activityStatus.operative;
    },
  },
  metaInfo() {
    return {
      title: 'Area utente - CR Pagamenti',
      meta: [
        { property: 'og:title', content: 'Area utente - CR Pagamenti' },
        { property: 'og:site_name', content: 'CR Pagamenti' },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'index,follow' },
      ],
    };
  },
  methods: {
    changePreloaderStatus(state) {
      this.hasLoaded = state;
      console.debug('UserAnalysisHistory setpreloader', !state);
      this.$store.dispatch('setPreloader', !state);
    },
    routeTo(path) {
      this.$router.replace(path);
    },
  },
};
</script>

<style scoped>

</style>
