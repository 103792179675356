<template>
  <user-structure
    hide-button
    hide-switch
    title="Nuova segnalazione"
  >
    <template #body>
      <b-card class="border-0">
        <user-report-wizard :request-type="requestType"/>
      </b-card>
    </template>
  </user-structure>
</template>

<script>
const UserStructure = () => import('@/components/layout/UserStructure.vue');
const UserReportWizard = () => import('@/components/wizards/UserReportWizard.vue');

export default {
  name: 'UserDashboard',
  components: {
    UserReportWizard,
    UserStructure,
  },
  props: {
    requestType: String,
  },
  data() {
    return {
      hasLoaded: null,
    };
  },
  computed: {
    shoutBoxData() {
      if (this.requestType === '') {
        return 'Segnalazioni';
      }
      if (this.requestType === 'company') {
        return 'Segnalazione Azienda';
      }
      return 'Segnalazione Persona';
    },
  },
  metaInfo() {
    return {
      title: `${this.shoutBoxData} - CR Pagamenti`,
      meta: [
        { property: 'og:title', content: `${this.shoutBoxData} - CR Pagamenti` },
        { property: 'og:site_name', content: 'CR Pagamenti' },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'noindex,nofollow' },
      ],
    };
  },
  methods: {},
};
</script>
