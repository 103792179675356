import Vue from 'vue';
import { toastFailure, toastSuccess, toastUnableToComplete } from '@/utils/toast';
import InvoiceService from '@/services/invoice.service';
import { isNotEmpty } from '@/utils/validators';

const initialState = {
  loading: 0,
  invoiceList: {
    loading: false,
    content: null,
    pageable: null,
    invoicesTotal: null,
  },
  currentInvoice: null,
  statusUpdatable: {},
  plafondList: {
    loading: false,
    content: null,
    pageable: null,
    plafondsTotal: null,
  },
  companyPlafondUsage: [],
  companyPlafondTypeUsage: {},
};

const invoice = {
  namespaced: true,
  state: initialState,
  getters: {
    isLoading: (state) => state.loading !== 0,
    hasInvoiceList: (state) => isNotEmpty(state.invoiceList.pageable),
    invoiceList: (state) => state.invoiceList.content || [],
    invoiceListPageable: (state) => state.invoiceList.pageable || {},
    invoicesListTotal: (state) => state.invoiceList.invoicesTotal || {},
    getCurrentInvoice: (state) => state.currentInvoice,
    hasCurrentInvoice: (state) => isNotEmpty(state.currentInvoice),
    getInvoiceNextStatusUpdatable: (state) => (state.statusUpdatable || {}).statuses || {},
    getInvoiceDocumentTypesUpdatable: (state) => (state.statusUpdatable || {}).documentTypesEditable || [],
    hasPlafondList: (state) => isNotEmpty(state.plafondList.pageable),
    plafondList: (state) => state.plafondList.content || [],
    plafondListPageable: (state) => state.plafondList.pageable || {},
    plafondListTotal: (state) => state.plafondList.plafondsTotal || {},
    companyPlafondUsages: (state) => state.companyPlafondUsage || [],
    companyPlafondTypeUsages: (state) => state.companyPlafondTypeUsage || {},
  },
  actions: {
    invoicesList({ commit }, {
      search, reset,
    }) {
      commit('setInvoice', { currentInvoice: null, status: {} });
      commit('setLoading', true);
      if (reset) {
        commit('clearInvoiceSearch');
      }
      return InvoiceService.invoicesList(search).then(
        (response) => {
          commit('invoiceSearchSuccess', { data: response.data });

          InvoiceService.invoicesFilterTotal(search).then(
            (response1) => {
              commit('invoiceTotalSearchSuccess', response1.data);
              commit('setLoading', false);
              return Promise.resolve(response.data);
            },
            (error) => {
              commit('setLoading', false);
              toastUnableToComplete();
              return Promise.reject(error);
            },
          );
        },
        (error) => {
          commit('clearInvoiceSearch');
          commit('setLoading', false);
          toastUnableToComplete();
          return Promise.reject(error);
        },
      );
    },
    clearInvoice({ commit }) {
      commit('setInvoice', { currentInvoice: null, status: {} });
    },
    checkStatusInvoice({ commit }, { invoiceId, currentInvoice }) {
      commit('setLoading', true);
      return InvoiceService.checkStatusInvoice(invoiceId).then(
        (responseStatus) => {
          if (currentInvoice) {
            commit('setInvoice', { currentInvoice, status: responseStatus.data });
          } else {
            commit('setStatusWorkflowInvoice', responseStatus.data);
          }
          commit('setLoading', false);
          return Promise.resolve(currentInvoice || responseStatus.data);
        },
        (errorStatus) => {
          console.log('error in get status updatable', errorStatus);
          if (currentInvoice) {
            commit('setInvoice', { currentInvoice, status: {} });
          } else {
            commit('setStatusWorkflowInvoice', {});
          }
          commit('setLoading', false);
          return Promise.resolve(currentInvoice || {});
        },
      );
    },
    getInvoice({ commit }, invoiceId) {
      commit('setLoading', true);
      return InvoiceService.loadInvoice(invoiceId).then(
        (response) => {
          commit('setLoading', false);
          this.dispatch('invoice/checkStatusInvoice', { invoiceId, currentInvoice: response.data });
        },
        (error) => {
          commit('setLoading', false);
          toastUnableToComplete();
          return Promise.reject(error);
        },
      );
    },
    updateStatusInvoice({ commit }, { invoiceId, status, noteStatus }) {
      commit('setLoading', true);
      return InvoiceService.updateStatusInvoice({ invoiceId, status, noteStatus }).then(
        (response) => {
          commit('setStatusWorkflowInvoice', response.data);
          this.dispatch('invoice/checkStatusInvoice', { invoiceId });
          commit('setLoading', false);
        },
        (error) => {
          commit('setLoading', false);
          toastUnableToComplete();
          return Promise.reject(error);
        },
      );
    },
    updateScoringCrpInvoice({ commit }, invoiceId) {
      commit('setLoading', true);
      return InvoiceService.updateScoringCrpStatus(invoiceId).then(
        (response) => {
          commit('setStatusWorkflowInvoice', response.data);
          this.dispatch('invoice/checkStatusInvoice', { invoiceId, currentInvoice: response.data });
          commit('setLoading', false);
        },
        (error) => {
          commit('setLoading', false);
          toastUnableToComplete();
          return Promise.reject(error);
        },
      );
    },
    deleteInvoice({ commit }, invoiceId) {
      commit('setLoading', true);
      return InvoiceService.deleteInvoice(invoiceId).then(
        (response) => {
          commit('setInvoice', { currentInvoice: null, status: {} });
          commit('setLoading', false);
          return Promise.resolve(response.data);
        },
        (error) => {
          commit('setLoading', false);
          toastUnableToComplete();
          return Promise.reject(error);
        },
      );
    },
    loadPlafondsList({ commit }, {
      search, reset,
    }) {
      commit('setPlafond', { currentPlafond: null, status: {} });
      commit('setLoading', true);
      if (reset) {
        commit('clearPlafondSearch');
      }
      return InvoiceService.plafondsList(search).then(
        (response) => {
          commit('plafondSearchSuccess', { data: response.data });

          commit('setLoading', false);
          return Promise.resolve(response.data);
        },
        (error) => {
          commit('clearPlafondSearch');
          commit('setLoading', false);
          toastUnableToComplete();
          return Promise.reject(error);
        },
      );
    },
    savePlafond({ commit }, plafond) {
      commit('setLoading', true);
      return InvoiceService.savePlafond(plafond).then(
        (response) => {
          toastSuccess('Salvataggio completato');
          commit('setLoading', false);
          return Promise.resolve(response);
        },
        (responseError) => {
          commit('setLoading', false);
          toastFailure('Salvataggio non completato');
          return Promise.reject(responseError);
        },
      );
    },
    plafondsUsage({ commit }, companyId) {
      commit('setLoading', true);
      return InvoiceService.plafondsUsage(companyId).then(
        (response) => {
          commit('setPlafondsUsage', response.data);
          commit('setLoading', false);
          return Promise.resolve(response.data);
        },
        (responseError) => {
          commit('setLoading', false);
          toastUnableToComplete();
          return Promise.reject(responseError);
        },
      );
    },
    verifyUploadPaymentInvoice({ commit }, uploadPaymentCode) {
      commit('setLoading', true);
      return InvoiceService.verifyUploadPaymentInvoice(uploadPaymentCode).then(
        (response) => {
          commit('setLoading', false);
          if (response.status > 399) {
            return Promise.resolve(false);
          }
          return Promise.resolve(response.data);
        },
        (responseError) => {
          commit('setLoading', false);
          return Promise.reject(responseError);
        },
      );
    },
  },
  mutations: {
    setLoading(state, loading) {
      state.loading += (loading ? 1 : -1);
    },
    clearInvoiceSearch(state) {
      const current = state.invoiceList || { };
      Vue.set(state, 'invoiceList', {
        ...current,
        content: null,
        pageable: null,
        invoicesTotal: null,
      });
    },
    invoiceSearchSuccess(state, { data }) {
      const { content, ...pageable } = data;
      const current = state.invoiceList || { };
      Vue.set(state, 'invoiceList', { ...current, content, pageable });
    },
    invoiceTotalSearchSuccess(state, data) {
      const current = state.invoiceList || { };
      Vue.set(state, 'invoiceList', { ...current, invoicesTotal: data });
    },
    setInvoice(state, { currentInvoice, status }) {
      state.currentInvoice = currentInvoice;
      state.statusUpdatable = status;
    },
    setStatusWorkflowInvoice(state, status) {
      state.statusUpdatable = status;
    },
    clearPlafondSearch(state) {
      const current = state.plafondList || { };
      state.companyPlafondUsage = [];
      state.companyPlafondTypeUsage = {};
      Vue.set(state, 'plafondList', {
        ...current,
        content: null,
        pageable: null,
      });
    },
    plafondSearchSuccess(state, { data }) {
      const { content, ...pageable } = data;
      const current = state.plafondList || { };
      Vue.set(state, 'plafondList', { ...current, content, pageable });
    },
    setPlafond(state, { currentPlafond, status }) {
      state.currentPlafond = currentPlafond;
      state.statusUpdatable = status;
    },
    setPlafondsUsage(state, usages) {
      state.companyPlafondUsage = usages;
      const usage = {};
      usages.forEach((row) => {
        if (!usage[row.plafondType]) {
          usage[row.plafondType] = {
            usage: 0,
            totalPlafond: 0,
          };
        }
        usage[row.plafondType].usage += row.usage;
        usage[row.plafondType].totalPlafond += row.globalPlafondLimit;
      });
      state.companyPlafondTypeUsage = usage;
    },
  },
};

export default invoice;
