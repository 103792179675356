<template>
  <main class="main--not-logged">
    <div class="banner">
      <img
        :src="require('../assets/img/architecture-g19c3e4465_1920_low.jpg')"
        class="banner__img"
      />
    </div>
    <div class="main-content">
      <shout-box
        :data="shoutBoxData"
      >
        <template slot="custom-content">
          <div class="shoutbox__custom-content">
            <form-register
              :token="token"
              :invite-email="email"
            />
          </div>
        </template>
      </shout-box>
    </div>
    <footer-component />
  </main>
</template>

<script>
const FormRegister = () => import('@/components/forms/FormRegister.vue');
const ShoutBox = () => import('@/components/ShoutBox.vue');
const FooterComponent = () => import('@/components/layout/FooterComponent.vue');

// token t=YjE4ZWRhZTctYjhkZC00OWJiLWFkNmQtNDQwOTY2YTk2ZmE2fHBhZ3Vhcm9AZ21haWwuY29t

export default {
  name: 'Register',
  components: {
    FormRegister,
    ShoutBox,
    FooterComponent,
  },
  data() {
    return {
      shoutBoxData: {
        title: 'Accedi o <span class="text-warning">registrati</span> per utilizzare tutti i servizi di CR Pagamenti',
        subtitle: 'Certifica la tua affidabilità nella gestione dei rapporti commerciali'
          + ' e nella gestione dei pagamenti, migliora il tuo scoring chiedendo i feedback'
          + ' ai tuoi fornitori',
      },
      token: null,
      email: null,
    };
  },
  metaInfo() {
    return {
      title: 'Registrazione utente - CR Pagamenti',
      meta: [
        { property: 'og:title', content: 'Registrazione utente - CR Pagamenti' },
        { property: 'og:site_name', content: 'CR Pagamenti' },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'index,follow' },
      ],
    };
  },
  mounted() {
    if (this.$store.getters['auth/isLogged']) {
      this.$router.replace('/dashboard-utente');
      return;
    }
    // console.debug('Register setpreloader', false);
    this.$store.dispatch('setPreloader', false);
    console.log('query', this.$route.query);
    const { t } = this.$route.query;
    if (t) {
      const values = atob(t);
      if (values.includes('|')) {
        const invite = values.split('|');
        // eslint-disable-next-line prefer-destructuring
        this.token = invite[0];
        // eslint-disable-next-line prefer-destructuring
        this.email = invite[1];
      }
    }
  },
};
</script>
