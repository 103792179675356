import Vue from 'vue';
import Vuex from 'vuex';
import auth from '@/store/auth.module';
import subject from '@/store/subject.module';
import documents from '@/store/documents.module';
import tableUtils from '@/store/tableUtils.module';
import score from '@/store/score.module';
import analysis from '@/store/analysis.module';
import invoice from '@/store/invoice.module';
import wallet from '@/store/wallet.module';
import sendmail from '@/store/sendmail.module';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    preloader: true,
    dashboardState: 0,
    lastMessage: null,
    exportTokens: [],
  },
  getters: {
    isPreloading: (state) => state.preloader,
    getDashboardState: (state) => state.dashboardState,
  },
  mutations: {
    setPreloaderStatus(state, loading) {
      state.preloader = loading;
    },
    setDashboardState(state, loading) {
      state.dashboardState = loading;
    },
    setLastMessage(state, msg) {
      state.lastMessage = msg;
    },
    appendExportToken(state, downToken) {
      const { exportTokens } = state;
      state.exportTokens = [...exportTokens, downToken];
    },
  },
  actions: {
    setPreloader({ commit }, status) {
      commit('setPreloaderStatus', status);
    },
    setDashboard({ commit }, status) {
      commit('setDashboardState', status);
    },
    processWebsocket({ commit, dispatch }, message) {
      console.log('Receive msg', message);
      commit('setLastMessage', message);
      if (message.context === 'COMPANY') {
        console.log('process company msg');
        // processMessageCompany(message);
        // processMessageAida(message);
      } else if (message.context === 'USERANALYSIS') {
        // processMessageAnalysis(message);
        console.log('process analisys msg');
        dispatch('analysis/processMessageUserAnalysis', message);
      } else if (message.context === 'REPORT') {
        // processMessageUserReport(message);
        console.log('process report msg');
      } else if (message.context === 'PERSON') {
        console.log('process person generated msg');
        dispatch('subject/processMessagePerson', message);
      } else if (message.context === 'EMAIL') {
        console.log('process sendmail preview msg');
        dispatch('sendmail/processMessagePreviewEmail', message);
      } else if (message.context === 'DOCUMENT-GENERATED') {
        console.log('process doc generated  msg');
        if (message.command === 'CREATED') {
          const { exportTokens, preloader } = this.state;
          console.log('check doc generated  token', this.state, this.state.exportTokens, exportTokens, preloader);
          if (exportTokens.find((id) => id === message.entityId)) {
            window.open(`/generated/${message.data}`);
          } else if (!message.retry) {
            setTimeout(() => {
              const msg = { ...message, retry: true };
              dispatch('processWebsocket', msg);
            }, 2000);
          }
        }
      }
    },
    addExportToken({ commit }, exportToken) {
      commit('appendExportToken', exportToken);
    },
  },
  modules: {
    auth,
    subject,
    documents,
    tableUtils,
    score,
    analysis,
    invoice,
    wallet,
    sendmail,
  },
});
